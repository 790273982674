import { Flex } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../../Components/Sidebar";
import MasterCreationForm from "./MasterCreationForm";

const MasterCreation = () => {
  return (
    <Flex>
      <Sidebar />
      <MasterCreationForm />
    </Flex>
  );
};

export default MasterCreation;
