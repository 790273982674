import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Select as ChakraSelect,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthProvider";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import axios from "axios";
import {backendBaseURL} from '../../../AppConstants';


const FinanceDashboard = () => {
  const toast = useToast();
  const navigation = useNavigate();
  const {
    fetchfinanceGenerationNamesList,
    fetchfinancerNamesList, 
  } = useAuth();

  const calculateUtilizedLimit = (financerName) => {
    const filteredRecords = fetchfinanceGenerationNamesList.filter(
      (record) => record.financerOrganizationName === financerName
    );

    const sum = filteredRecords.reduce((acc, record) => {
      return acc + parseFloat(record.exporterInvoiceValue || 0);
    }, 0);

    return sum;
  };

  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };

  const handleDeleteFinancerDetails = async (_id) => {
    try {
      await axios.delete(
        `${backendBaseURL}/deleteFinancerDetails/${_id}`
      );
      
      toast({
        position: "top",
        title: "Financer Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Financer Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Flex justifyContent={"space-between"} p={5}>
            <Flex justifyContent={"flex-start"} gap={5}>
              <IoMdArrowRoundBack
                onClick={() => navigation("/finance")}
                size={20}
                style={{ marginTop: 5 }}
              />
              <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
                Finance Dashboard
              </Heading>
            </Flex>
            <Flex justifyContent={"flex-start"} gap={5}>
            <Button
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              onClick={() => navigation("/finance")}
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
             Generate Finance
            </Button>
            
            </Flex>
          </Flex>

          <Box p={10}>
            <TableContainer>
              <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                <Thead>
                  <Tr boxShadow={"sm"}>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Name Of Financer
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Sanction Limit
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Utilized Limt
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Available Limit
                    </Th>

                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "start", color: "teal" }}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
          {fetchfinancerNamesList.length > 0 &&
            fetchfinancerNamesList.map((el) => {
              const utilizedLimit = calculateUtilizedLimit(el.financerOrganizationName);
              const sanctionLimit = parseFloat(el.limitOfFinance || 0);
              const availableLimit = sanctionLimit - utilizedLimit;

              return (
                <Tr key={el._id} boxShadow={"md"}>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {el.financerOrganizationName}
                    </Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.limitOfFinance}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{utilizedLimit}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{availableLimit}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Flex gap={3}>
                      <FiEdit
                        style={{ color: "teal", marginTop: 2 }}
                      />
                      <Popover>
                        <PopoverTrigger>
                          <Button
                            size={"xs"}
                            color="white"
                            bgColor={"white"}
                            _hover={{ bgColor: "white" }}
                          >
                            <MdDeleteForever
                              style={{ color: "teal" }}
                              size={18}
                            />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody mt={5}>
                            Are you sure you want to delete <br />
                            financer?
                          </PopoverBody>
                          <PopoverFooter
                            display="flex"
                            justifyContent="flex-end"
                            gap={2}
                          >
                            <ButtonGroup size="xs"></ButtonGroup>
                            <ButtonGroup size="xs">
                              <Button
                                color="green"
                                _hover={{
                                  color: "white",
                                  bgColor: "green",
                                }}
                                onClick={() => handleDeleteFinancerDetails(el._id)}
                                fontWeight={"bold"}
                              >
                                Yes
                              </Button>
                            </ButtonGroup>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
              </Table>
              <Box>
                {fetchfinancerNamesList.length === 0 && (
                  <Alert
                    status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <AlertIcon boxSize={8} />
                    No Record Found
                  </Alert>
                )}
              </Box>
            </TableContainer>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default FinanceDashboard;
