import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "../Logins/AdminLogin";
import Dashboard from "../Pages/Admin/Dashboard/Dashboard";
import Home from "../Pages/Admin/Home/Home";
import SalescontractDetails from "../Pages/Admin/SalesContract/SalescontractDetails";
import MasterCreation from "../Pages/Admin/MasterCreation/MasterCreation";
import SalesContract from "../Pages/Admin/SalesContract/SalesContract";
import Finance from "../Pages/Admin/Finance/Finance";
import FinanceDash from "../Pages/Admin/Finance/FinanceDash";
import AddMoreShipment from "../Pages/Admin/SalesContract/AddMoreShipment";
import EditShipment from "../Pages/Admin/SalesContract/EditShipment";


const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLogin />} />
      <Route path="/home" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/salescontract" element={<SalesContract />} />
      <Route path="/finance" element={<Finance />} />
      <Route path="/financedashboard" element={<FinanceDash />} />
      <Route path="/salescontractdetails" element={<SalescontractDetails />} />
      <Route path="/addmoreshipment" element={<AddMoreShipment />} />
      <Route path="/editshipment" element={<EditShipment />} />
      <Route path="/mastercreation" element={<MasterCreation />} />
    </Routes>
  );
};

export default AllRoutes;
