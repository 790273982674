import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select as ChakraSelect,
  useToast,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Alert,
  AlertIcon,
  useDisclosure,
  DrawerFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import Navbar from "../../../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useAuth } from "../../../Context/AuthProvider";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { backendBaseURL } from "../../../AppConstants";
const MasterCreationForm = () => {
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };

  const InputStyleEdit = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "teal",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };
  const [selectedMaster, setSelectedMaster] = useState("exporter");
  const [formData, setFormData] = useState({});
  const {
    fetchexporterNamesList,
    fetchimporterNamesList,
    fetchclearingAgentNamesList,
    fetchbankNamesList,
    fetchitemNamesssList,
    fetchfinancerNamesList,
    fetchshippingLinesList,
    fetchtransporterNamesList,
  } = useAuth();
  const navigation = useNavigate();
  const toast = useToast();
  const [value, setValue] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (selectedOption) => {
    setValue(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      exportercountry: selectedOption.label,
    }));
  };

  const handleSelectChange = (event) => {
    setSelectedMaster(event.target.value);
    setFormData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    let apiUrl = "";
    switch (selectedMaster) {
      case "exporter":
        apiUrl = `${backendBaseURL}/postExporterDetails`;
        break;
      case "importer":
        apiUrl = `${backendBaseURL}/postImporterDetails`;
        break;
      case "clearing_agent":
        apiUrl = `${backendBaseURL}/postClearingAgentDetails`;
        break;
      case "bank":
        apiUrl = `${backendBaseURL}/postBankDetails`;
        break;
      case "item_name":
        apiUrl = `${backendBaseURL}/postItemNameDetails`;
        break;
      case "financer":
        apiUrl = `${backendBaseURL}/postFinancerDetails`;
        break;
      case "shippingline":
        apiUrl = `${backendBaseURL}/postShippingLineDetails`;
        break;
      case "transporter":
        apiUrl = `${backendBaseURL}/postTransporterNameDetails`;
        break;

      default:
        setIsLoading(false);
        return;
    }

    try {
      const response = await axios.post(apiUrl, formData);
      console.log("Response:", response.data);
      toast({
        position: "top",
        title: `${response.data.message}`,
        description: "Details Added Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFormData({});
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "Fields are not valid",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const InputStyle = {
    width: { base: "100%", md: "70%", lg: "550px" },
    focusBorderColor: "teal.400",
  };

  const handleDeleteExporter = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteExporterDetails/${_id}`);
      toast({
        position: "top",
        title: "Exporter Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Exporter",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteImporter = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteImporterDetails/${_id}`);
      toast({
        position: "top",
        title: "Importer Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Importer",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClearingAgent = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteClearingAgentDetails/${_id}`);
      toast({
        position: "top",
        title: "Clearing Agent Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Clearing Agent",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteBank = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteBankDetails/${_id}`);
      toast({
        position: "top",
        title: "Bank Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Bank",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItemName = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteItemNameDetails/${_id}`);
      toast({
        position: "top",
        title: "Item Name Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Item Name",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteFinancer = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteFinancerDetails/${_id}`);
      toast({
        position: "top",
        title: "Financer Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Financer",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteShippingLine = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteShippingLineDetails/${_id}`);
      toast({
        position: "top",
        title: "Shipping Line Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Shipping Line",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTransporter = async (_id) => {
    try {
      await axios.delete(
        `${backendBaseURL}/deleteTransporterNameeDetails/${_id}`
      );
      toast({
        position: "top",
        title: "Transporter Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Transporter",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [currentCase, setCurrentCase] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const [editMasterCreation, setEditMasterCreation] = useState({
    exporter: {
      exporterName: "",
      exporterEmailAddress: "",
      exporterAddress: "",
      exporterContactPersonNo: "",
      exportertelephoneNo: "",
      exportercountry: "",
    },
    importer: {
      importerName: "",
      importerAddress: "",
      importerEmailAddress: "",
      importerContactPersonNo: "",
      importertelephoneNo: "",
    },
    clearing_agent: {
      clearingAgentName: "",
      clearingAgentFee: "",
      // add other clearing agent-specific fields here
    },
    // Add other cases as needed
  });

  const handleEdit = (item, type) => {
    setCurrentCase(type);
    setSelectedItem(item);
    setEditMasterCreation((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],

        ...(type === "exporter" && {
          exporterName: item.exporterName || "",
          exporterEmailAddress: item.exporterEmailAddress || "",
          exporterAddress: item.exporterAddress || "",
          exporterContactPersonNo: item.exporterContactPersonNo || "",
          exportertelephoneNo: item.exportertelephoneNo || "",
          exportercountry: item.exportercountry || "",
        }),
        ...(type === "importer" && {
          importerName: item.importerName || "",
          importerAddress: item.importerAddress || "",
          importerEmailAddress: item.importerEmailAddress || "",
          importerContactPersonNo: item.importerContactPersonNo || "",
          importertelephoneNo: item.importertelephoneNo || "",
        }),
        ...(type === "clearing_agent" && {
          clearingAgentName: item.clearingAgentName || "",
          clearingAgentFee: item.clearingAgentFee || "",
        }),
        // Add cases for other types
      },
    }));
    onSidebarOpen();
  };

  const renderFormFields = () => {
    switch (selectedMaster) {
      case "exporter":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Exporter Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="exporterName"
                    value={formData.exporterName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Email Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="exporterEmailAddress"
                    value={formData.exporterEmailAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="exporterAddress"
                    value={formData.exporterAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>

                <Box {...InputStyle}>
                  <FormLabel {...FormLabelStyle}>Select Country</FormLabel>
                  <Select
                    options={options}
                    value={value}
                    onChange={changeHandler}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Contact Person No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="exporterContactPersonNo"
                    value={formData.exporterContactPersonNo || ""}
                    onChange={handleInputChange}
                    maxLength={10}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Telephone No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="exportertelephoneNo"
                    value={formData.exportertelephoneNo || ""}
                    onChange={handleInputChange}
                    maxLength={10}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Exporter List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Exporter Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Exporter Email Address
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Address
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Country
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Contact Person No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Telephone No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchexporterNamesList.length > 0 &&
                      fetchexporterNamesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.exporterName}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.exporterEmailAddress}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.exporterAddress}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.exportercountry}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.exporterContactPersonNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.exportertelephoneNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "exporter")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />{" "}
                                    exporter?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteExporter(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchexporterNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "importer":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Importer Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="importerName"
                    value={formData.importerName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Email Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="importerEmailAddress"
                    value={formData.importerEmailAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="importerAddress"
                    value={formData.importerAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Contact Person No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="importerContactPersonNo"
                    value={formData.importerContactPersonNo || ""}
                    onChange={handleInputChange}
                    maxLength={10}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Telephone No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="importertelephoneNo"
                    value={formData.importertelephoneNo || ""}
                    maxLength={10}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Importer List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Importer Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Importer Email Address
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Address
                      </Th>

                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Contact Person No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Telephone No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchimporterNamesList.length > 0 &&
                      fetchimporterNamesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.importerName}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.importerEmailAddress}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.importerAddress}
                            </Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.importerContactPersonNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.importertelephoneNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "importer")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />
                                    importer?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteImporter(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchimporterNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "clearing_agent":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Company Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="agentCompanyName"
                    value={formData.agentCompanyName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Email Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="agentEmailAddress"
                    value={formData.agentEmailAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Contact Person Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="agentContactPersonName"
                    value={formData.agentContactPersonName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Contact Person No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="agentContactPersonNo"
                    value={formData.agentContactPersonNo || ""}
                    onChange={handleInputChange}
                    maxLength={10}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="agentAddress"
                    value={formData.agentAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Clearing Agent List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Company Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Email Address
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Address
                      </Th>

                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Contact Person Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Contact Person No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchclearingAgentNamesList.length > 0 &&
                      fetchclearingAgentNamesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.agentCompanyName}
                            </Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.agentEmailAddress}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.agentAddress}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.agentContactPersonName}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.agentContactPersonNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "clearing_agent")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />{" "}
                                    agent?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteClearingAgent(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchclearingAgentNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "bank":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="bankName"
                    value={formData.bankName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="bankAddress"
                    value={formData.bankAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Account No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="bankAccountNo"
                    value={formData.bankAccountNo || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Bank List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Bank Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Address
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Account No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "start", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchbankNamesList.length > 0 &&
                      fetchbankNamesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.bankName}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.bankAddress}</Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.bankAccountNo}</Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "bank")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />
                                    bank?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() => handleDeleteBank(el._id)}
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchbankNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "item_name":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Enter Sl No</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="itemSlNo"
                    value={formData.itemSlNo || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Enter Item Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="itemName"
                    value={formData.itemName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Item List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Item Sl No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Item Name
                      </Th>

                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "start", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchitemNamesssList.length > 0 &&
                      fetchitemNamesssList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.itemSlNo}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.itemName}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "item_name")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />
                                    item?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteItemName(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchitemNamesssList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "financer":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>
                    Financer Organization Name{" "}
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerOrganizationName"
                    value={formData.financerOrganizationName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Address</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerAddress"
                    value={formData.financerAddress || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>
                    Contact Person Name & No{" "}
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerContactPersonNo"
                    value={formData.financerContactPersonNo || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Bank Account No </FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerBankAccountNo"
                    value={formData.financerBankAccountNo || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Swift Code</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerSwiftCode"
                    value={formData.financerSwiftCode || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerBankName"
                    value={formData.financerBankName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Limit Of Finance</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="limitOfFinance"
                    value={formData.limitOfFinance || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="financerCurrency"
                    value={formData.financerCurrency || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Exporter List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Financer Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Address
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Contact Person Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Bank Account No
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Swift Code
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Bank Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Limit Of Finance
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Currency
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchfinancerNamesList.length > 0 &&
                      fetchfinancerNamesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerOrganizationName}
                            </Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerAddress}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerContactPersonNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerBankAccountNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerSwiftCode}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerBankName}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.limitOfFinance}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.financerCurrency}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={3}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "financer")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />
                                    financer?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteFinancer(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchfinancerNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "shippingline":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Shipping Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="shippingName"
                    value={formData.shippingName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box>
                  <FormLabel {...FormLabelStyle}>GST Number</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="shippingGSTNumber"
                    value={formData.shippingGSTNumber || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>State</FormLabel>
                  <ChakraSelect
                    name="shippingState"
                    value={formData.shippingState || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                    style={{ focusBorderColor: "teal.400" }}
                  >
                    <option value="" disabled>
                      State
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman & Nicobar">
                      Andaman And Nicobar Islands
                    </option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Dadar & Nagar Haveli">
                      Dadra And Nagar Haveli
                    </option>
                    <option value="Daman & Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Puducherry">Puducherry</option>
                  </ChakraSelect>
                  {/* <Input
                    type="text"
                    placeholder=""
                    name="shippingState"
                    value={formData.shippingState || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  /> */}
                </Box>
                <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Shipping Line List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Shipping Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        GST Number
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        State
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "start", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchshippingLinesList.length > 0 &&
                      fetchshippingLinesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.shippingName}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.shippingGSTNumber}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.shippingState}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "shippingline")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />
                                    shipping line?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteShippingLine(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchshippingLinesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      case "transporter":
        return (
          <Box>
            <FormControl>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Transporter Name</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="transporterName"
                    value={formData.transporterName || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>

                <Box>
                  <FormLabel {...FormLabelStyle}>Contact Number</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="transporterNumber"
                    value={formData.transporterNumber || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"} mt={5}>
                <Box>
                  <FormLabel {...FormLabelStyle}>Transporter Id</FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    name="transporterId"
                    value={formData.transporterId || ""}
                    onChange={handleInputChange}
                    {...InputStyle}
                  />
                </Box>
                <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
              </Flex>
            </FormControl>
            <Box p={10}>
              <Flex justifyContent={"flex-start"}>
                <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                  mb={5}
                >
                  Transporter List
                </Heading>
              </Flex>
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Transporter Name
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Transporter ID
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Contact Number
                      </Th>

                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "start", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {fetchtransporterNamesList.length > 0 &&
                      fetchtransporterNamesList?.map((el) => (
                        <Tr key={el._id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.transporterName}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>{el.transporterId}</Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button {...ButtonStyle}>
                              {el.transporterNumber}
                            </Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Flex gap={5}>
                              <FiEdit
                                style={{ color: "teal", marginTop: 2 }}
                                onClick={() => handleEdit(el, "transporter")}
                              />
                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="white"
                                    bgColor={"white"}
                                    _hover={{ bgColor: "white" }}
                                  >
                                    <MdDeleteForever
                                      style={{ color: "red" }}
                                      size={18}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody mt={5}>
                                    Are you sure you want to delete <br />
                                    transporter?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs"></ButtonGroup>
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() =>
                                          handleDeleteTransporter(el._id)
                                        }
                                        fontWeight={"bold"}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <Box>
                  {fetchtransporterNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const getHeadingText = () => {
    switch (selectedMaster) {
      case "exporter":
        return "Enter Exporter Details";
      case "importer":
        return "Enter Importer Details";
      case "clearing_agent":
        return "Enter Clearing Agent Details";
      case "bank":
        return "Enter Bank Details";
      case "item_name":
        return "Enter Item Name Details";
      case "financer":
        return "Enter Financer Details";
      case "shippingline":
        return "Enter Shipping Details";
      case "transporter":
        return "Enter Transporter Details";
      default:
        return "Master Creation Details";
    }
  };

  const handleMasterCreationUpdate = async (_id) => {};

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          minH={750}
          p={{ base: "5", md: "5" }}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Flex justifyContent={"flex-start"} gap={5} p={5}>
            <IoMdArrowRoundBack
              onClick={() => navigation("/home")}
              size={20}
              style={{ marginTop: 5 }}
            />

            <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
              {getHeadingText()}
            </Heading>
          </Flex>

          <Flex justifyContent={"space-between"} p={5}>
            <ChakraSelect
              placeholder=""
              w={350}
              onChange={handleSelectChange}
              style={{ focusBorderColor: "teal.400" }}
            >
              <option value="exporter">Exporter</option>
              <option value="importer">Importer</option>
              <option value="clearing_agent">Clearing agent</option>
              <option value="bank">Bank</option>
              <option value="item_name">Item Name</option>
              <option value="financer">Financer</option>
              <option value="shippingline">Shipping Line</option>
              <option value="transporter">Transporter</option>
            </ChakraSelect>
            <Button
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              onClick={handleSubmit}
              isLoading={isLoading}
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              Save
            </Button>
          </Flex>

          {renderFormFields()}
        </Box>
        <Box>
          <Drawer
            isOpen={isSidebarOpen}
            onClose={onSidebarClose}
            placement="right"
            size={"lg"}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleMasterCreationUpdate(selectedItem._id);
              }}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                  Edit{" "}
                  {currentCase.charAt(0).toUpperCase() + currentCase.slice(1)}{" "}
                  Details
                </DrawerHeader>
                <DrawerBody>
                  {currentCase === "exporter" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={editMasterCreation.exporter.exporterName}
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.exporter,
                                exporterName: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Exporter Name</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={
                            editMasterCreation.exporter.exporterEmailAddress
                          }
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.exporter,
                                exporterEmailAddress: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Exporter Email Address</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={editMasterCreation.exporter.exporterAddress}
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.exporter,
                                exporterAddress: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Exporter Address</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={
                            editMasterCreation.exporter.exporterContactPersonNo
                          }
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.exporter,
                                exporterContactPersonNo: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Contact Person No</Box>
                      </Box>

                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={
                            editMasterCreation.exporter.exportertelephoneNo
                          }
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.exporter,
                                exportertelephoneNo: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Telephone No</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={editMasterCreation.exporter.exportercountry}
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.exporter,
                                exportercountry: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Country</Box>
                      </Box>
                    </>
                  )}

                  {currentCase === "importer" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={editMasterCreation.importer.importerName}
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.importer,
                                importerName: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Importer Name</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={
                            editMasterCreation.importer.importerEmailAddress
                          }
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.importer,
                                importerEmailAddress: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Importer Email Address</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={editMasterCreation.importer.importerAddress}
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.importer,
                                importerAddress: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Importer Address</Box>
                      </Box>
                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={
                            editMasterCreation.importer.importerContactPersonNo
                          }
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.importer,
                                importerContactPersonNo: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Contact Person No</Box>
                      </Box>

                      <br />
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          value={
                            editMasterCreation.importer.importertelephoneNo
                          }
                          onChange={(e) =>
                            setEditMasterCreation({
                              ...editMasterCreation,
                              exporter: {
                                ...editMasterCreation.importer,
                                importertelephoneNo: e.target.value,
                              },
                            })
                          }
                        />
                        <Box {...BoxStyle}>Telephone No</Box>
                      </Box>
                      <br />
                    </>
                  )}

                  {currentCase === "clearing_agent" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentName}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentName: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Name</Box>
                      </Box>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentFee}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentFee: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Fee</Box>
                      </Box>
                    </>
                  )}

                  {currentCase === "bank" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentName}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentName: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Bank Name</Box>
                      </Box>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentFee}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentFee: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Fee</Box>
                      </Box>
                    </>
                  )}

                  {currentCase === "item_name" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentName}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentName: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Item Name</Box>
                      </Box>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentFee}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentFee: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Fee</Box>
                      </Box>
                    </>
                  )}

                  {currentCase === "financer" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentName}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentName: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Financer Name</Box>
                      </Box>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentFee}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentFee: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Fee</Box>
                      </Box>
                    </>
                  )}

                  {currentCase === "shippingline" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentName}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentName: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Shipping Name</Box>
                      </Box>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentFee}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentFee: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Fee</Box>
                      </Box>
                    </>
                  )}

                  {currentCase === "transporter" && (
                    <>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentName}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentName: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Transporter Name</Box>
                      </Box>
                      <Box position="relative">
                        <Input
                          {...InputStyleEdit}
                          type="text"
                          // value={editFinance.clearingAgentFee}
                          // onChange={(e) =>
                          //   setEditFinance({
                          //     ...editFinance,
                          //     clearingAgentFee: e.target.value,
                          //   })
                          // }
                        />
                        <Box {...BoxStyle}>Clearing Agent Fee</Box>
                      </Box>
                    </>
                  )}
                </DrawerBody>
                <DrawerFooter>
                  <Button
                    type="submit"
                    bgGradient="linear(to-r, teal.300, teal.100)"
                    _hover={{
                      bgGradient: "linear(to-r, teal.100, teal.400)",
                    }}
                  >
                    Update{" "}
                    {currentCase.charAt(0).toUpperCase() + currentCase.slice(1)}{" "}
                    Details
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </form>
          </Drawer>
        </Box>
      </Flex>
    </Box>
  );
};

export default MasterCreationForm;
