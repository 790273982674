import { Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import SalescontractDetails from './SalescontractDetails'

const SalesContract = () => {
  return (
    <Flex>
    <Sidebar />
    <SalescontractDetails />
  </Flex>
  )
}

export default SalesContract