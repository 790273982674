import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HamburgerIcon, BellIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();

  const handleNotificationClick = () => {
    // Implement notification click logic here
  };

  const handleProfileClick = () => {
    navigate("/");
  };

  const navigate = useNavigate();
  const toast = useToast();

  const handleLogout = () => {
    localStorage.removeItem("token");
    toast({
      position: "top",
      title: "Logged out",
      description: "You have been successfully logged out.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    navigate("/");
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify={{ base: "space-between", md: "flex-end" }}
      paddingX={6}
      paddingY={4}
      color="teal"
      boxShadow="sm"
      zIndex="sticky"
      borderRadius={10}
    >
      {/* Hamburger Menu */}
      <IconButton
        icon={<HamburgerIcon />}
        variant="ghost"
        display={{ base: "block", md: "none" }}
        onClick={onToggle}
        aria-label="Toggle navigation"
      />

      <Box display={{ base: isOpen ? "block" : "none", md: "block" }}>
        {/* Notification Bell */}
        <IconButton
          icon={<BellIcon />}
          ml={2}
          colorScheme="green"
          onClick={handleNotificationClick}
          aria-label="Notifications"
        />

        {/* Profile Picture */}
        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                size="md"
                name="Admin"
                src={`https://www.interactivebrokers.co.in/images/web/hero-microsite-fund-admin.jpg`}
              />
            }
            colorScheme="white"
            ml={2}
          />
          
          <MenuList>
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default Navbar;
