import { Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import FinanceForm from './FinanceForm'

const Finance = () => {
  return (
    <Flex>
      <Sidebar />
      <FinanceForm />
    </Flex>
  )
}

export default Finance