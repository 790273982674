import { Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import AddMShipDetails from './AddMShipDetails'

const AddMoreShipment = () => {
  return (
    <Flex>
    <Sidebar />
    <AddMShipDetails />
  </Flex>
  )
}

export default AddMoreShipment