import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Select as ChakraSelect,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthProvider";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { backendBaseURL } from "../../../AppConstants";
import { ChevronDownIcon } from "@chakra-ui/icons";
const FinanceForm = () => {
  const toast = useToast();
  const navigation = useNavigate();
  const {
    salesContractNumberList,
    financerNamesList,
    fetchfinanceGenerationNamesList,
    shippingDetailsData,
  } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };
  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const InputStyle = {
    width: { base: "100%", md: "70%", lg: "550px" },
    focusBorderColor: "teal.400",
  };

  const SubInputStyle = {
    width: { base: "100%", md: "70%", lg: "270px" },
    focusBorderColor: "teal.400",
  };

  const [formData, setFormData] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    salesContractNumber: "",
    exporterName: "",
    exporterInvoiceValue: "",
    exporterInvoiceValues: [],
    invoiceDate: "",
    financeCurrency: "",
    creditTakenDate: "",
    creditDueDate: "",
    creditPeriod: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeleteFinancerDetails = async (_id) => {
    try {
      await axios.delete(
        `${backendBaseURL}/deleteFinanceGenerationDetails/${_id}`
      );
      onClose();
      toast({
        position: "top",
        title: "Financer Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Financer Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // const filterDetailsBySalesContractNumber = (salesContractNumber) => {
  //   const detail = shippingDetailsData.find(
  //     (detail) => detail.salesContractNumber === salesContractNumber
  //   );
  //   if (detail) {
  //     const exporterName = detail.shipmentWiseData.exporterName
  //       ? detail.shipmentWiseData.exporterName
  //       : detail.shipmentWiseData.hssName
  //       ? detail.shipmentWiseData.hssName
  //       : "";
  //     const invoiceDate = detail.rows[0]?.exporterInvoiceDate
  //       ? new Date(detail.rows[0].exporterInvoiceDate).toLocaleDateString(
  //           "en-CA"
  //         )
  //       : "";

  //     const exporterInvoiceValues = detail.rows.map((row, index) => {
  //       return {
  //         [`exporterInvoiceValue${index + 1}`]: row?.invoiceValue || "",
  //       };
  //     });

  //     console.log("exporterInvoiceValues",exporterInvoiceValues)

  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       exporterName,
  //       invoiceDate,
  //       ...exporterInvoiceValues.reduce(
  //         (acc, curr) => ({ ...acc, ...curr }),
  //         {}
  //       ),
  //     }));
  //   }
  // };

  // useEffect(() => {
  //   if (formData.salesContractNumber) {
  //     filterDetailsBySalesContractNumber(formData.salesContractNumber);
  //   }
  // }, [formData.salesContractNumber]);

  const filterDetailsBySalesContractNumber = (salesContractNumber) => {
    const detail = shippingDetailsData.find(
      (detail) => detail.salesContractNumber === salesContractNumber
    );
    if (detail) {
      const exporterName = detail.shipmentWiseData.exporterName
        ? detail.shipmentWiseData.exporterName
        : detail.shipmentWiseData.hssName
        ? detail.shipmentWiseData.hssName
        : "";
      const invoiceDate = detail.rows[0]?.exporterInvoiceDate
        ? new Date(detail.rows[0].exporterInvoiceDate).toLocaleDateString(
            "en-CA"
          )
        : "";

      const exporterInvoiceValues = detail.rows.map(
        (row) => row?.invoiceValue || ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        exporterName,
        invoiceDate,
        exporterInvoiceValues,
      }));
    }
  };

  useEffect(() => {
    if (formData.salesContractNumber) {
      filterDetailsBySalesContractNumber(formData.salesContractNumber);
    }
  }, [formData.salesContractNumber]);

  const [selectedValues, setSelectedValues] = useState([]);
  const [sum, setSum] = useState(0);

  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) => {
      const updatedValues = prev.includes(value)
        ? prev.filter((v) => v !== value)
        : [...prev, value];
      const newSum = updatedValues.reduce((acc, curr) => acc + Number(curr), 0); // Convert to number here
      setSum(newSum);
      setFormData((prevFormData) => ({
        ...prevFormData,
        exporterInvoiceValue: newSum,
      }));
      return updatedValues;
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    let apiUrl = `${backendBaseURL}/postFinanceGenerationDetails`;

    try {
      const response = await axios.post(apiUrl, formData);
      console.log("Response:", response.data);
      toast({
        position: "top",
        title: `${response.data.message}`,
        description: "Details Added Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "Fields are not valid",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const InputStyleEdit = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "teal",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const [selectedItem, setSelectedItem] = useState(null);
  // console.log("selected", selectedItem);
  const [editFinance, setEditFinance] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    salesContractNumber: "",
    exporterName: "",
    exporterInvoiceValue: "",
    invoiceDate: "",
    financeCurrency: "",
    creditTakenDate: "",
    creditDueDate: "",
    creditPeriod: "",
  });

  const handleFinanceUpdate = async (_id) => {
    try {
      const apiEndpoint = `${backendBaseURL}/editFinanceGenerationDetails/${_id}`;
      const response = await axios.patch(apiEndpoint, editFinance);
      if (response.status !== 200) {
        throw new Error("Failed to update finance details");
      }
      setSelectedItem(null);
      setEditFinance({
        financerOrganizationName: "",
        transactionRefNo: "",
        salesContractNumber: "",
        exporterName: "",
        exporterInvoiceValue: "",
        invoiceDate: "",
        financeCurrency: "",
        creditTakenDate: "",
        creditDueDate: "",
        creditPeriod: "",
      });
      toast({
        position: "top",
        title: "Finance Details Updated Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Finance Details",
        description: "An error occurred while updating the Finance Details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditFinance = (el) => {
    setSelectedItem(el);
    setEditFinance({
      financerOrganizationName: el.financerOrganizationName,
      transactionRefNo: el.transactionRefNo,
      salesContractNumber: el.salesContractNumber,
      exporterName: el.exporterName,
      exporterInvoiceValue: el.exporterInvoiceValue,
      invoiceDate: el.invoiceDate,
      financeCurrency: el.financeCurrency,
      creditTakenDate: el.creditTakenDate,
      creditDueDate: el.creditDueDate,
      creditPeriod: el.creditPeriod,
    });
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Flex justifyContent={"space-between"} p={5}>
            <Flex justifyContent={"flex-start"} gap={5}>
              <IoMdArrowRoundBack
                onClick={() => navigation("/home")}
                size={20}
                style={{ marginTop: 5 }}
              />
              <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
                Finance Details
              </Heading>
            </Flex>
            <Flex justifyContent={"flex-start"} gap={5}>
              {/* <Button
                color="white"
                backgroundColor="teal.400"
                borderRadius="15"
                onClick={() => navigation("/financedashboard")}
                _hover={{ backgroundColor: "green.200", color: "black" }}
              >
                Go To Dashboard
              </Button> */}
              <Button
                color="white"
                backgroundColor="teal.400"
                borderRadius="15"
                onClick={handleSubmit}
                isLoading={isLoading}
                _hover={{ backgroundColor: "green.200", color: "black" }}
              >
                Save Finance
              </Button>
            </Flex>
          </Flex>

          <FormControl>
            <Flex justifyContent={"space-around"} mt={5}>
              <Box>
                <FormLabel {...FormLabelStyle}>Financer Name</FormLabel>
                <ChakraSelect
                  name="financerOrganizationName"
                  value={formData.financerOrganizationName || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                >
                  <option value="" disabled>
                    Financer Name
                  </option>
                  {financerNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                <FormLabel {...FormLabelStyle}>Transaction Ref No</FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  name="transactionRefNo"
                  value={formData.transactionRefNo || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                />
              </Box>
            </Flex>
            <Flex justifyContent={"space-around"} mt={5}>
              <Box>
                <FormLabel {...FormLabelStyle}>Sales Contract No</FormLabel>
                <ChakraSelect
                  name="salesContractNumber"
                  value={formData.salesContractNumber || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                >
                  <option value="" disabled>
                    Sales Contract No
                  </option>
                  {salesContractNumberList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                <FormLabel {...FormLabelStyle}>Exporter Name </FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  name="exporterName"
                  value={formData.exporterName || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                />
              </Box>
            </Flex>
            <Flex justifyContent={"space-around"} mt={5} wrap="wrap">
              <Flex  direction={{ base: "column", md: "row" }} gap={4}>
                <Box>
                  <FormLabel {...FormLabelStyle}>
                    Exporter Invoice Value
                  </FormLabel>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      {...SubInputStyle}
                    >
                      Select Invoice Values
                    </MenuButton>
                    <MenuList>
                      {formData.exporterInvoiceValues.map((value, index) => (
                        <MenuItem key={index}>
                          <Checkbox
                            isChecked={selectedValues.includes(value)}
                            onChange={() => handleCheckboxChange(value)}
                          >
                            {value}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
                <Box mt={{ base: 4, md: 0 }}>
                  <FormLabel {...FormLabelStyle}>Total Invoice Value</FormLabel>
                  <Input
                    name="exporterInvoiceValue"
                    value={formData.exporterInvoiceValue || sum}
                    readOnly
                    onChange={handleInputChange}
                    {...SubInputStyle}
                  />
                </Box>
              </Flex>

              <Box>
                <FormLabel {...FormLabelStyle}>Invoice Date</FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  name="invoiceDate"
                  value={formData.invoiceDate || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                />
              </Box>
            </Flex>
         

            <Flex justifyContent={"space-around"} mt={5}>
              <Box>
                <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                <ChakraSelect
                  name="financeCurrency"
                  value={formData.financeCurrency || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                >
                  <option value="" disabled>
                    HSS Value
                  </option>
                  <option value="USD">USD</option>
                  <option value="INR">INR</option>
                  <option value="EUR">Euro</option>
                  <option value="QAR">Qatar Riyal</option>
                  <option value="SAR">Saudi Riyal</option>
                </ChakraSelect>
              </Box>
              <Box>
                <FormLabel {...FormLabelStyle}>Credit Taken Date</FormLabel>
                <Input
                  type="date"
                  placeholder=""
                  name="creditTakenDate"
                  value={formData.creditTakenDate || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                />
              </Box>
            </Flex>
            <Flex justifyContent={"space-around"} mt={5}>
              <Box>
                <FormLabel {...FormLabelStyle}>Credit Period</FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  name="creditPeriod"
                  value={formData.creditPeriod || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                />
              </Box>
              <Box>
                <FormLabel {...FormLabelStyle}>Credit Due Date</FormLabel>
                <Input
                  type="date"
                  placeholder=""
                  name="creditDueDate"
                  value={formData.creditDueDate || ""}
                  onChange={handleInputChange}
                  {...InputStyle}
                />
              </Box>
            </Flex>
          </FormControl>
          <Box p={10}>
            <Flex justifyContent={"flex-start"}>
              <Heading
                as="h6"
                size="xs"
                textAlign={"start"}
                textDecoration={"underline"}
                mb={5}
              >
                Finance List
              </Heading>
            </Flex>
            <TableContainer>
              <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                <Thead>
                  <Tr boxShadow={"sm"}>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Sales Contract No
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Financer Name
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Transaction Ref No
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Exporter Name
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Exporter Invoice Value
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Invoice Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Currency
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Credit Taken Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Credit Due Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Credit Period
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {fetchfinanceGenerationNamesList.length > 0 &&
                    fetchfinanceGenerationNamesList?.map((el) => (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.salesContractNumber}
                          </Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.financerOrganizationName}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.transactionRefNo}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.exporterName}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.exporterInvoiceValue}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.invoiceDate}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.financeCurrency}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.creditTakenDate}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.creditDueDate}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.creditPeriod}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Flex gap={3}>
                            <FiEdit
                              style={{ color: "teal", marginTop: 2 }}
                              onClick={() => {
                                onSidebarOpen();
                                handleEditFinance(el);
                              }}
                            />
                            <Popover>
                              <PopoverTrigger>
                                <Button
                                  size={"xs"}
                                  color="white"
                                  bgColor={"white"}
                                  _hover={{ bgColor: "white" }}
                                >
                                  <MdDeleteForever
                                    style={{ color: "red" }}
                                    size={18}
                                  />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody mt={5}>
                                  Are you sure you want to delete <br />
                                  financer?
                                </PopoverBody>
                                <PopoverFooter
                                  display="flex"
                                  justifyContent="flex-end"
                                  gap={2}
                                >
                                  <ButtonGroup size="xs"></ButtonGroup>
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      onClick={() =>
                                        handleDeleteFinancerDetails(el._id)
                                      }
                                      fontWeight={"bold"}
                                    >
                                      Yes
                                    </Button>
                                  </ButtonGroup>
                                </PopoverFooter>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              {selectedItem && (
                <Box>
                  <Drawer
                    isOpen={isSidebarOpen}
                    onClose={onSidebarClose}
                    placement="right"
                    size={"lg"}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleFinanceUpdate(selectedItem._id);
                      }}
                    >
                      <DrawerOverlay />
                      <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Edit Finance Details</DrawerHeader>
                        <DrawerBody>
                          <Box position="relative" mb={5}>
                            <Select
                              name="financerOrganizationName"
                              value={editFinance.financerOrganizationName || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  financerOrganizationName: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Financer Name
                              </option>
                              {financerNamesList.map((financer) => (
                                <option key={financer} value={financer}>
                                  {financer}
                                </option>
                              ))}
                            </Select>
                            <Box {...BoxStyle}>Financer Name</Box>
                          </Box>

                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.transactionRefNo}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  transactionRefNo: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Transaction Ref No</Box>
                          </Box>
                          <br />
                          <Box position="relative" mb={5}>
                            <Select
                              name="salesContractNumber"
                              value={editFinance.salesContractNumber || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  salesContractNumber: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Sales Contract No
                              </option>
                              {salesContractNumberList.map((financer) => (
                                <option key={financer} value={financer}>
                                  {financer}
                                </option>
                              ))}
                            </Select>
                            <Box {...BoxStyle}>Sales Contract No</Box>
                          </Box>
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.exporterName}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  exporterName: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Exporter Name</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.exporterInvoiceValue}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  exporterInvoiceValue: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Exporter Invoice Value</Box>
                          </Box>
                          <br />
                          {/* <Box position="relative" mb={5}>
                            <Select
                              name="exporterInvoiceValue"
                              value={editFinance.exporterInvoiceValue || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  exporterInvoiceValue: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Exporter Invoice Value
                              </option>
                              {salesContractNumberList.map((financer) => (
                                <option key={financer} value={financer}>
                                  {financer}
                                </option>
                              ))}
                            </Select>
                            <Box {...BoxStyle}>Exporter Invoice Value</Box>
                          </Box> */}
                          <Box position="relative" mb={5}>
                            <Select
                              name="financeCurrency"
                              value={editFinance.financeCurrency || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  financeCurrency: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Currency
                              </option>
                              <option value="USD">USD</option>
                              <option value="INR">INR</option>
                              <option value="EUR">Euro</option>
                              <option value="QAR">Qatar Riyal</option>
                              <option value="SAR">Saudi Riyal</option>
                            </Select>
                            <Box {...BoxStyle}>Currency</Box>
                          </Box>

                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.creditPeriod}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  creditPeriod: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Credit Period</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="date"
                              value={
                                editFinance.invoiceDate
                                  ? editFinance.invoiceDate.slice(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  invoiceDate: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Invoice Date</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="date"
                              value={
                                editFinance.creditTakenDate
                                  ? editFinance.creditTakenDate.slice(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  creditTakenDate: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Credit Taken Date</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="date"
                              value={
                                editFinance.creditDueDate
                                  ? editFinance.creditDueDate.slice(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  creditDueDate: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Credit Due Date</Box>
                          </Box>
                          <br />
                        </DrawerBody>
                        <DrawerFooter>
                          <Button
                            // onClick={handleFinanceUpdate}
                            type="submit"
                            bgGradient="linear(to-r, teal.300,  teal.100)"
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                          >
                            Update Finance Details
                          </Button>
                        </DrawerFooter>
                      </DrawerContent>
                    </form>
                  </Drawer>
                </Box>
              )}
              <Box>
                {fetchfinanceGenerationNamesList.length === 0 && (
                  <Alert
                    status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <AlertIcon boxSize={8} />
                    No Record Found
                  </Alert>
                )}
              </Box>
            </TableContainer>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default FinanceForm;
