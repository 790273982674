import {
  Alert,
  AlertIcon,
  Avatar,
  Select as ChakraSelect,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Pagination } from "../../../Components/Pagination";
import Navbar from "../../../Components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { IoPieChart } from "react-icons/io5";
import { BsInfoCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useAuth } from "../../../Context/AuthProvider";
import axios from "axios";
import { backendBaseURL } from "../../../AppConstants";
const Dashboard = () => {
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };
  const navigation = useNavigate();
  const {
    paginationData,
    fetchfinanceGenerationNamesList,
    fetchrePaymentList,
    fetchfinancerNamesList,
    financerNamesList,
  } = useAuth();

  const toast = useToast();

  const [page, setPage] = useState(1);
  let perPage = 10;
  let totalPage = Math.ceil(fetchfinancerNamesList.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let reverseData = [...fetchfinancerNamesList].reverse();
  let contractSignedData = [...reverseData].slice(start, end);

  const handleDeleteFinancerDetails = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteFinancerDetails/${_id}`);

      toast({
        position: "top",
        title: "Financer Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Financer Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const calculateUtilizedLimit = (financerName) => {
    const filteredRecords = fetchfinanceGenerationNamesList.filter(
      (record) => record.financerOrganizationName === financerName
    );

    const sum = filteredRecords.reduce((acc, record) => {
      return acc + parseFloat(record.exporterInvoiceValue || 0);
    }, 0);

    return sum;
  };

  const calculateAmountInINR = (financerName) => {
    const filteredRecords = fetchrePaymentList.filter(
      (record) => record.financerOrganizationName === financerName
    );

    const sum = filteredRecords.reduce((acc, record) => {
      return acc + parseFloat(record.amountInINR || 0);
    }, 0);

    return sum;
  };

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const [formData, setFormData] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    netAmountPayable: "",
    additionalCharges: "",
    totalAmount: "",
    repaymentDate: "",
    repaymentCurrency: "",
    reExchangeRate: "",
    amountInINR: "",
    bankCharges: "",
  });

  useEffect(() => {
    const netAmount = parseFloat(formData.netAmountPayable) || 0;
    const additionalCharges = parseFloat(formData.additionalCharges) || 0;
    const totalAmount = netAmount + additionalCharges;

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalAmount: totalAmount.toFixed(2),
    }));
  }, [formData.netAmountPayable, formData.additionalCharges]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  // const handleSubmit = async () => {
  //   const currentFinancer = formData.financerOrganizationName;

  //   // Fetch the sanction limit for the current financer
  //   const sanctionLimit = parseFloat(
  //     contractSignedData.find(
  //       (el) => el.financerOrganizationName === currentFinancer
  //     )?.limitOfFinance || 0
  //   );

  //   // Calculate the utilized limit and amount in INR for the current financer
  //   const utilizedLimit = calculateUtilizedLimit(currentFinancer);
  //   const amountInINR = calculateAmountInINR(currentFinancer);

  //   // Calculate the available limit
  //   const availableLimit = sanctionLimit - utilizedLimit + amountInINR;

  //   // Debugging: Log the values
  //   console.log("Sanction Limit:", sanctionLimit);
  //   console.log("Utilized Limit:", utilizedLimit);
  //   console.log("Amount in INR:", amountInINR);
  //   console.log("Available Limit:", availableLimit);

  //   // Calculate the maximum amount that can be added without exceeding the sanction limit
  //   const maxAddableAmount = sanctionLimit - availableLimit;

  //   // Validation: Check if adding the current amount will exceed the sanction limit
  //   const amountToAdd = parseFloat(formData.amountToAdd || 0);
  //   if (amountToAdd + availableLimit > sanctionLimit) {
  //     alert(`Adding ${amountToAdd} exceeds the sanction limit for ${currentFinancer}.
  // Sanction Limit: ${sanctionLimit}
  // Utilized Limit: ${utilizedLimit}
  // Amount in INR: ${amountInINR}
  // Available Limit: ${availableLimit}
  // You can add up to ${maxAddableAmount} without exceeding the sanction limit.`);
  //     return;
  //   }

  //   setIsLoading(true);
  //   const apiUrl = `${backendBaseURL}/postrePaymentDetails`;

  //   try {
  //     const response = await axios.post(apiUrl, formData);
  //     console.log("Response:", response.data);
  //     toast({
  //       position: "top",
  //       title: `${response.data.message}`,
  //       description: "Details Added Successfully",
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 3000);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast({
  //       position: "top",
  //       title: `${error.response.data.message}`,
  //       description: "Fields are not valid",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    const currentFinancer = formData.financerOrganizationName;

    // Fetch the sanction limit for the current financer
    const sanctionLimit = parseFloat(
      contractSignedData.find(
        (el) => el.financerOrganizationName === currentFinancer
      )?.limitOfFinance || 0
    );

    // Calculate the utilized limit and amount in INR for the current financer
    const utilizedLimit = calculateUtilizedLimit(currentFinancer);
    const amountInINR = calculateAmountInINR(currentFinancer);

    // Calculate the available limit
    const availableLimit = sanctionLimit - utilizedLimit + amountInINR;

    // Debugging: Log the values
    console.log("Sanction Limit:", sanctionLimit);
    console.log("Utilized Limit:", utilizedLimit);
    console.log("Amount in INR:", amountInINR);
    console.log("Available Limit:", availableLimit);

    // Calculate the maximum amount that can be added without exceeding the sanction limit
    const maxAddableAmount = sanctionLimit - availableLimit;

    // Validation: Check if the initial available limit exceeds the sanction limit
    if (availableLimit > sanctionLimit) {
      alert(`Available limit for ${currentFinancer} already exceeds the sanction limit.
  Sanction Limit: ${sanctionLimit}
  Utilized Limit: ${utilizedLimit}
  Amount in INR: ${amountInINR}
  Available Limit: ${availableLimit}`);
      return;
    }

    // Validation: Check if adding the current amount will exceed the sanction limit
    const amountToAdd = parseFloat(formData.amountToAdd || 0);
    if (amountToAdd + availableLimit > sanctionLimit) {
      alert(`Adding ${amountToAdd} exceeds the sanction limit for ${currentFinancer}.
  Sanction Limit: ${sanctionLimit}
  Utilized Limit: ${utilizedLimit}
  Amount in INR: ${amountInINR}
  Available Limit: ${availableLimit}
  You can add up to ${maxAddableAmount} without exceeding the sanction limit.`);
      return;
    }

    setIsLoading(true);
    const apiUrl = `${backendBaseURL}/postrePaymentDetails`;

    try {
      const response = await axios.post(apiUrl, formData);
      console.log("Response:", response.data);
      toast({
        position: "top",
        title: `${response.data.message}`,
        description: "Details Added Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "Fields are not valid",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        flexWrap="wrap"
        gap={3}
        mb={4}
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "flex-start" }}
      >
        <Card w={300} p={15} bgGradient="linear(to-r, teal.100, green.200)">
          <CardBody>
            <Flex
              spacing="3"
              // textAlign="center"
              align="center"
              justify="start"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                borderRadius="20px"
                p={2}
                bg="green.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IoPieChart size={50} color="white" />
              </Box>
              <Text ml={5}>
                <Text fontSize="2xl" textAlign="start" fontWeight={"bold"}>
                  {paginationData.length}
                </Text>
                <Text fontSize="md" textAlign="start" fontWeight={"bold"}>
                  No of Sales Contract
                </Text>
              </Text>
            </Flex>
          </CardBody>
        </Card>
        <Card w={300} p={15} bgGradient="linear(to-r, #DCFCE7, #DCFCE7)">
          <CardBody>
            <Flex
              spacing="3"
              // textAlign="center"
              align="center"
              justify="start"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                borderRadius="20px"
                p={2}
                bg="green.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IoPieChart size={50} color="white" />
              </Box>
              <Text ml={5}>
                <Text fontSize="2xl" textAlign="start" fontWeight={"bold"}>
                  09
                </Text>
                <Text fontSize="md" textAlign="start" fontWeight={"bold"}>
                  Upcoming Shipment
                </Text>
              </Text>
            </Flex>
          </CardBody>
        </Card>
        <Card w={320} p={15} bgGradient="linear(to-r, green.100, teal.200)">
          <CardBody>
            <Flex
              spacing="3"
              // textAlign="center"
              align="center"
              justify="start"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                borderRadius="20px"
                p={2}
                bg="green.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IoPieChart size={50} color="white" />
              </Box>
              <Text ml={5}>
                <Text fontSize="2xl" textAlign="start" fontWeight={"bold"}>
                  85
                </Text>
                <Text fontSize="md" textAlign="start" fontWeight={"bold"}>
                  No of Consignment Received
                </Text>
              </Text>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Heading textAlign={"start"} size={"md"} p={4}>
          Financer Details
        </Heading>
        <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          onClick={() => {
            setOverlay(<OverlayOne />);
            onOpen();
          }}
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          Repayment
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size={"xl"}>
          {overlay}
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Repayment Form</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <Flex
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={4}
                  mt={5}
                >
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Financer Name</FormLabel>
                    <ChakraSelect
                      name="financerOrganizationName"
                      value={formData.financerOrganizationName || ""}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Financer Name
                      </option>
                      {financerNamesList.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </ChakraSelect>
                  </Box>
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>
                      Transaction Ref No
                    </FormLabel>
                    <Input
                      type="text"
                      name="transactionRefNo"
                      value={formData.transactionRefNo || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={4}
                  mt={5}
                >
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>
                      Net Amount Payable
                    </FormLabel>
                    <Input
                      type="text"
                      name="netAmountPayable"
                      value={formData.netAmountPayable || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>
                      Additional Charges
                    </FormLabel>
                    <Input
                      type="text"
                      name="additionalCharges"
                      value={formData.additionalCharges || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={4}
                  mt={5}
                >
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Re-Payment Date</FormLabel>
                    <Input
                      type="date"
                      name="repaymentDate"
                      value={formData.repaymentDate || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Amount</FormLabel>
                    <Input
                      type="text"
                      name="totalAmount"
                      value={formData.totalAmount || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={4}
                  mt={5}
                >
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                    <ChakraSelect
                      name="repaymentCurrency"
                      value={formData.repaymentCurrency || ""}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Currency
                      </option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="EUR">Euro</option>
                      <option value="QAR">Qatar Riyal</option>
                      <option value="SAR">Saudi Riyal</option>
                    </ChakraSelect>
                  </Box>
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    <Input
                      type="text"
                      name="reExchangeRate"
                      value={formData.reExchangeRate || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={4}
                  mt={5}
                >
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Amount In INR</FormLabel>
                    <Input
                      type="text"
                      name="amountInINR"
                      value={formData.amountInINR || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box flex="1">
                    <FormLabel {...FormLabelStyle}>Bank Charges</FormLabel>
                    <Input
                      type="text"
                      name="bankCharges"
                      value={formData.bankCharges || ""}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Flex>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                color="white"
                backgroundColor="teal.400"
                borderRadius="15"
                onClick={() => handleSubmit()}
                isLoading={isLoading}
                _hover={{ backgroundColor: "green.200", color: "black" }}
              >
                Submit Re-Payment
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Name Of Financer
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Sanction Limit
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Utilized Limt
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Available Limit
                  </Th>

                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "start", color: "teal" }}
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {contractSignedData.length > 0 &&
                  contractSignedData.map((el) => {
                    const utilizedLimit = calculateUtilizedLimit(
                      el.financerOrganizationName
                    );
                    const sanctionLimit = parseFloat(el.limitOfFinance || 0);
                    const amountInINR = calculateAmountInINR(
                      el.financerOrganizationName
                    );
                    const availableLimit =
                      sanctionLimit - utilizedLimit + amountInINR;

                    // if (availableLimit > sanctionLimit) {
                    //   alert(
                    //     `Available limit for ${el.financerOrganizationName} exceeds the sanction limit.`
                    //   );
                    //   availableLimit = sanctionLimit;
                    // }

                    return (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            {...ButtonStyle}
                            onClick={() => {
                              setOverlay(<OverlayOne />);
                              onOpen();
                            }}
                          >
                            {el.financerOrganizationName}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            {...ButtonStyle}
                            onClick={() => {
                              setOverlay(<OverlayOne />);
                              onOpen();
                            }}
                          >
                            {el.limitOfFinance}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            {...ButtonStyle}
                            onClick={() => {
                              setOverlay(<OverlayOne />);
                              onOpen();
                            }}
                          >
                            {utilizedLimit}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            {...ButtonStyle}
                            onClick={() => {
                              setOverlay(<OverlayOne />);
                              onOpen();
                            }}
                          >
                            {availableLimit}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Flex gap={3}>
                            <FiEdit
                              style={{ color: "teal", marginTop: 2 }}
                              onClick={() => {
                                setOverlay(<OverlayOne />);
                                onOpen();
                              }}
                            />
                            <Popover>
                              <PopoverTrigger>
                                <Button
                                  size={"xs"}
                                  color="white"
                                  bgColor={"white"}
                                  _hover={{ bgColor: "white" }}
                                >
                                  <MdDeleteForever
                                    style={{ color: "red" }}
                                    size={18}
                                  />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody mt={5}>
                                  Are you sure you want to delete <br />
                                  financer?
                                </PopoverBody>
                                <PopoverFooter
                                  display="flex"
                                  justifyContent="flex-end"
                                  gap={2}
                                >
                                  <ButtonGroup size="xs"></ButtonGroup>
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      onClick={() =>
                                        handleDeleteFinancerDetails(el._id)
                                      }
                                      fontWeight={"bold"}
                                    >
                                      Yes
                                    </Button>
                                  </ButtonGroup>
                                </PopoverFooter>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Box>
              {fetchfinancerNamesList.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Dashboard;
