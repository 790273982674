import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select as ChakraSelect,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useToast,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "../../../Components/Pagination";
import Navbar from "../../../Components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdArrowRoundBack, IoMdRemoveCircleOutline } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import Select from "react-select";
import countryList from "react-select-country-list";
import axios from "axios";
import { useAuth } from "../../../Context/AuthProvider";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { backendBaseURL } from "../../../AppConstants";
const SalesContractDetails = () => {
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };
  const toast = useToast();
  const [view, setView] = useState("list");
  const navigation = useNavigate();

  const {
    exporterNamesList,
    importerFirmNamesList,
    itemNamesList,
    bankNamesList,
    clearingAgentsList,
    shippingLinesList,
    transporterNamesList,
    paginationData,
    shippingDetailsData,
  } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [page, setPage] = useState(1);
  let perPage = 10;
  let totalPage = Math.ceil(paginationData.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let reverseData = [...paginationData].reverse();
  let contractSignedData = [...reverseData].slice(start, end);
  const [selectedSalesContractNumber, setSelectedSalesContractNumber] =
    useState(null);
  const navigateToSalesContract = (salesContractNumber) => {
    setView("addedContracts");
    setSelectedSalesContractNumber(salesContractNumber);
  };

  const navigateToEditSalesContract = (salesContractNumber) => {
    setView("edit");
    setSelectedSalesContractNumber(salesContractNumber);
  };

  const handleDeleteContract = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteSalesContractDetails/${_id}`);
      onClose();
      toast({
        position: "top",
        title: "Sales Contract Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Sales Contract",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteShipingDetails = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteShippingDetails/${_id}`);
      onClose();
      toast({
        position: "top",
        title: "Shipping Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Shipping Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderList = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} p={5}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => navigation("/home")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Sales Contract Details
          </Heading>
        </Flex>
        <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          onClick={() => setView("add")}
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          + Add Sales Contract
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
          <Thead>
            <Tr boxShadow={"sm"}>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Sales Contract No
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Exporter Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Contact Person
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Importing Firm Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Type Of Duty
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Type Of Import
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Item Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {contractSignedData.length > 0 &&
              contractSignedData?.map((el) => (
                <Tr key={el._id} boxShadow={"md"}>
                  <Link to="/home">
                    <Td style={{ textAlign: "center" }}>
                      <Wrap>
                        <WrapItem>
                          <Flex justifyContent={"space-around"} gap={2}>
                            <Avatar
                              size="xs"
                              name="Admin"
                              src="https://i.ibb.co/FWy9bGx/portrait-white-man-isolated.jpg"
                            />
                            <Heading size={"xs"}>
                              <Button {...ButtonStyle}>
                                {el.salesContractNumber}
                              </Button>
                            </Heading>
                          </Flex>
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </Link>

                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.exporterName}</Button>
                  </Td>

                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {el.salesContractContactPersonName}
                    </Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.importingFirmName}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.typeOfDuty}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.typeOfImport}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.itemName}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Flex gap={5}>
                      <BsInfoCircle
                        title="Info"
                        onClick={() =>
                          navigateToSalesContract(el.salesContractNumber)
                        }
                        style={{ color: "teal", cursor: "pointer" }}
                      />
                      <FiEdit
                        title="edit"
                        onClick={() =>
                          navigateToEditSalesContract(el.salesContractNumber)
                        }
                        style={{ color: "teal", cursor: "pointer" }}
                      />
                      {/* <MdDeleteForever style={{ color: "teal" }} /> */}
                      <Popover>
                        <PopoverTrigger title="Delete">
                          <Button
                            size={"xs"}
                            color="white"
                            bgColor={"white"}
                            _hover={{ bgColor: "white" }}
                          >
                            <MdDeleteForever
                              style={{ color: "red" }}
                              size={18}
                            />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody mt={5}>
                            Are you sure you want to delete <br />
                            contract?
                          </PopoverBody>
                          <PopoverFooter
                            display="flex"
                            justifyContent="flex-end"
                            gap={2}
                          >
                            <ButtonGroup size="xs"></ButtonGroup>
                            <ButtonGroup size="xs">
                              <Button
                                color="green"
                                _hover={{
                                  color: "white",
                                  bgColor: "green",
                                }}
                                onClick={() => handleDeleteContract(el._id)}
                                fontWeight={"bold"}
                              >
                                Yes
                              </Button>
                            </ButtonGroup>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Box>
          {contractSignedData.length === 0 && (
            <Alert
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={4}
            >
              <AlertIcon boxSize={8} />
              No Record Found
            </Alert>
          )}
        </Box>
      </TableContainer>
      <Flex justifyContent={"flex-end"} mt={5}>
        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
      </Flex>
    </Box>
  );

  const [singleContractData, setSingleContractData] = useState({});

  //console.log("singleContractData",singleContractData);
  const [singleShippingDetailsData, setSingleShippingDetailsData] = useState(
    []
  );
  //console.log("singleShippingDetailsData", singleShippingDetailsData);
  useEffect(() => {
    if (selectedSalesContractNumber) {
      const filtered = paginationData.filter(
        (item) => item.salesContractNumber === selectedSalesContractNumber
      );
      const filteredShipping = shippingDetailsData.filter(
        (item) => item.salesContractNumber === selectedSalesContractNumber
      );
      console.log(filtered, "filtered");
      setSingleContractData(filtered);
      setSingleShippingDetailsData(filteredShipping);
    }
  }, [selectedSalesContractNumber, paginationData, shippingDetailsData]);

  const navigate = useNavigate();
  const renderAddedContracts = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} p={5}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => setView("list")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Sales Contract Details
          </Heading>
          {selectedSalesContractNumber}
        </Flex>
        {/* <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          onClick={() => setView("add")}
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          + Edit Sales Contract
        </Button> */}
        <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          // onClick={() => setView("shipmentdetailsform")}
          // onClick={() => navigation("/addmoreshipment")}
          onClick={() =>
            navigate("/addmoreshipment", {
              state: { contract: singleContractData },
            })
          }
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          + Add More Shipment
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
          <Thead>
            <Tr boxShadow={"sm"}>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Sales Contract No
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Exporter Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Contact Person
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Importing Firm Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Type Of Duty
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Type Of Import
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Item Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Quantity
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Price
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Rate
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {singleContractData.length > 0 &&
              singleContractData?.map((el, index) => (
                <Tr key={el._id} boxShadow={"md"}>
                  <Link to="/home">
                    <Td style={{ textAlign: "center" }}>
                      <Wrap>
                        <WrapItem>
                          <Flex justifyContent={"space-around"} gap={2}>
                            <Avatar
                              size="xs"
                              name="Admin"
                              src="https://i.ibb.co/FWy9bGx/portrait-white-man-isolated.jpg"
                            />
                            <Heading size={"xs"}>
                              <Button {...ButtonStyle}>
                                {el.salesContractNumber}
                              </Button>
                            </Heading>
                          </Flex>
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </Link>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.exporterName}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {el.salesContractContactPersonName}
                    </Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.importingFirmName}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.typeOfDuty}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.typeOfImport}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.itemName}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.fixedQuantity}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>1200</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>1800000</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Flex gap={5}>
                      {/* <BsInfoCircle
                        onClick={() => setView("info")}
                        style={{ color: "green" }}
                      /> */}
                      <FiEdit
                        onClick={() => setView("edit")}
                        style={{ color: "teal" }}
                      />
                      <MdDeleteForever style={{ color: "red" }} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <br />
      <Flex justifyContent={"space-between"} p={5}>
        <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
          Shipping Details
        </Heading>
        {/* <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          // onClick={() => setView("shipmentdetailsform")}
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          + Add More Shipment
        </Button> */}
      </Flex>
      <TableContainer>
        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
          <Thead>
            <Tr boxShadow={"sm"}>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Sales Contract No
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Exporter Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Contact Person
              </Th>
              {/* <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Importing Firm Name
              </Th> */}
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Type Of Duty
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Type Of Import
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Item Name
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Quantity
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Price
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Rate
              </Th>
              <Th
                boxShadow={"sm"}
                style={{ textAlign: "center", color: "teal" }}
              >
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {singleShippingDetailsData.length > 0 &&
              singleShippingDetailsData?.map((el, index) => (
                <Tr boxShadow={"md"}>
                  <Link to="/home">
                    <Td style={{ textAlign: "center" }}>
                      <Wrap>
                        <WrapItem>
                          <Flex justifyContent={"space-around"} gap={2}>
                            <Avatar
                              size="xs"
                              name="Admin"
                              src="https://i.ibb.co/FWy9bGx/portrait-white-man-isolated.jpg"
                            />
                            <Heading size={"xs"}>
                              <Button {...ButtonStyle}>
                                {el.salesContractNumber}
                              </Button>
                            </Heading>
                          </Flex>
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </Link>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {el?.shipmentWiseData?.hssName
                        ? el?.shipmentWiseData?.hssName
                        : el?.shipmentWiseData?.exporterName}
                    </Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {singleContractData[0]?.salesContractContactPersonName}
                    </Button>
                  </Td>
                  {/* <Td style={{ textAlign: "center" }}>
                <Button {...ButtonStyle}>{el.rows[0]?.itemName}</Button>
              </Td> */}
                  {/* <Td style={{ textAlign: "center" }}>
                {el.rows.map((row, rowIndex) => (
                  <Button {...ButtonStyle} key={rowIndex}>
                    {Object.values(row).join(", ")}
                  </Button>
                ))}
              </Td> */}
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {singleContractData[0]?.typeOfDuty}
                    </Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>
                      {singleContractData[0]?.typeOfImport}
                    </Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.rows[0]?.itemName}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.rows[0]?.quantity}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.rows[0]?.rate}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Button {...ButtonStyle}>{el.rows[0]?.invoiceValue}</Button>
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    <Flex gap={5}>
                      {/* <BsInfoCircle
                        onClick={() => setView("info")}
                        style={{ color: "teal" }}
                      /> */}
                      <FiEdit
                        // onClick={() => setView("edit")}
                        onClick={() =>
                          navigate("/editshipment", {
                            state: { contract: singleContractData },
                          })
                        }
                        style={{ color: "teal" }}
                      />
                      <MdDeleteForever
                        onClick={() => handleDeleteShipingDetails(el?._id)}
                        style={{ color: "red" }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );

  const [formData, setFormData] = useState({
    salesContractNumber: "",
    salesContractDate: "",
    exporterName: "",
    salesContractContactPersonName: "",
    importingFirmName: "",
    typeOfDuty: "",
    typeOfImport: "",
    paymentMode: "",
    itemName: "",
    fixedQuantity: "",
    priceTerms: "",
    salesContractRemarks: "",
    floatingPriceRows: [],
    fixedPriceRows: [],
    advancePaymentDetails: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      if (name === "paymentMode" && value === "Floating Price") {
        return {
          ...prevData,
          [name]: value,
          floatingPriceRows: [
            {
              date: "",
              floatingitemName: "",
              quantity: "",
              lmePrice: "",
              rate: "",
              finalPrice: "",
            },
          ],
        };
      } else if (name === "paymentMode" && value === "Fixed Price") {
        return {
          ...prevData,
          [name]: value,
          fixedPriceRows: [
            {
              fixeditemName: "",
              quantity: "",
              itemUnit: "",
              price: "",
            },
          ],
        };
      } else if (name === "paymentMode") {
        return {
          ...prevData,
          [name]: value,
          floatingPriceRows: [],
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...formData.floatingPriceRows];
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      floatingPriceRows: newRows,
    });
  };

  const addRow = () => {
    setFormData({
      ...formData,
      floatingPriceRows: [
        ...formData.floatingPriceRows,
        {
          date: "",
          floatingitemName: "",
          quantity: "",
          lmePrice: "",
          rate: "",
          finalPrice: "",
        },
      ],
    });
  };

  const removeRow = () => {
    if (formData.floatingPriceRows.length > 1) {
      setFormData({
        ...formData,
        floatingPriceRows: formData.floatingPriceRows.slice(0, -1),
      });
    }
  };

  const handleFixedRowChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...formData.fixedPriceRows];
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      fixedPriceRows: newRows,
    });
  };

  const addFixedRow = () => {
    setFormData({
      ...formData,
      fixedPriceRows: [
        ...formData.fixedPriceRows,
        {
          fixeditemName: "",
          quantity: "",
          itemUnit: "",
          price: "",
        },
      ],
    });
  };

  const removeFixedRow = () => {
    if (formData.fixedPriceRows.length > 1) {
      setFormData({
        ...formData,
        fixedPriceRows: formData.fixedPriceRows.slice(0, -1),
      });
    }
  };

  const handleRowAdvancePaymentChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...formData.advancePaymentDetails];
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      advancePaymentDetails: newRows,
    });
  };

  const addAdvancePaymentRow = () => {
    setFormData((prevData) => ({
      ...prevData,
      advancePaymentDetails: [
        ...prevData.advancePaymentDetails,
        {
          date: "",
          bankName: "",
          transactionReferenceNo: "",
          amount: "",
          currency: "",
          exchangeRates: "",
          amountInINR: "",
          bankCharges: "",
        },
      ],
    }));
  };

  const removeAdvancePaymentRow = () => {
    if (formData.advancePaymentDetails.length > 1) {
      setFormData({
        ...formData,
        advancePaymentDetails: formData.advancePaymentDetails.slice(0, -1),
      });
    }
  };

  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const InputStyle = {
    width: { base: "100%", md: "70%", lg: "500px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  const SubInputStyle = {
    width: { base: "100%", md: "70%", lg: "240px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };
  const MultiInputStyle = {
    width: { base: "100%", md: "70%", lg: "260px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };
  const MiniMultiInputStyle = {
    width: { base: "100%", md: "70%", lg: "150px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  const handleSalesContract = async () => {
    let apiUrl = `${backendBaseURL}/postSalesContractDetails`;

    try {
      const response = await axios.post(apiUrl, formData);
      console.log("Response:", response.data);
      toast({
        position: "top",
        title: `${response.data.message}`,
        description: "Details Added Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFormData({
        salesContractNumber: formData.salesContractNumber,
        salesContractDate: "",
        exporterName: formData.exporterName,
        salesContractContactPersonName: "",
        importingFirmName: formData.importingFirmName,
        typeOfDuty: formData.typeOfDuty,
        typeOfImport: formData.typeOfImport,
        paymentMode: "",
        itemName: "",
        fixedQuantity: formData.fixedQuantity,
        priceTerms: "",
        salesContractRemarks: "",
        floatingPriceRows: [],
        fixedPriceRows: [],
        advancePaymentDetails: [],
      });
      setView("shipmentdetailsform");
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "Please Select The Required Fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderAddForm = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      mb={20}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} p={5}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => setView("list")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Add Sales Contract
          </Heading>
        </Flex>

        <Flex justifyContent={"space-between"} gap={5}>
          <Button
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            // onClick={() => setView("shipmentdetailsform")}
            onClick={() => {
              if (
                formData.salesContractNumber &&
                formData.typeOfDuty &&
                formData.typeOfImport
              ) {
                setView("shipmentdetailsform", true);
              } else {
                toast({
                  position: "top",
                  title: "Please Select The Required Fields",
                  description:
                    "Sales Contract Number, Type Of Duty & Type Of Import",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Shipping Details
          </Button>
          <Button
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            onClick={handleSalesContract}
            // onClick={() => setView("addedContracts")}
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            Save
          </Button>
          {/* <Button
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            onClick={() => setView("addedContracts")}
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            See
          </Button> */}
        </Flex>
      </Flex>
      <Box>
        <FormControl>
          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Sales Contract Number*</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="salesContractNumber"
                value={formData.salesContractNumber || ""}
                onChange={handleInputChange}
                {...InputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Contract Date*</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="salesContractDate"
                value={formData.salesContractDate || ""}
                onChange={handleInputChange}
                {...InputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Name*</FormLabel>
              <ChakraSelect
                name="exporterName"
                value={formData.exporterName || ""}
                onChange={handleInputChange}
                {...InputStyle}
              >
                <option value="" disabled>
                  Exporter Name
                </option>

                {exporterNamesList.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </ChakraSelect>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Contact Person Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="salesContractContactPersonName"
                value={formData.salesContractContactPersonName || ""}
                onChange={handleInputChange}
                {...InputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Importing Company Name</FormLabel>
              <ChakraSelect
                name="importingFirmName"
                value={formData.importingFirmName || ""}
                onChange={handleInputChange}
                {...InputStyle}
              >
                <option value="" disabled>
                  Importing Company Name
                </option>

                {importerFirmNamesList.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </ChakraSelect>
            </Box>
            <Flex justifyContent={"space-around"} gap={5}>
              <Box>
                <FormLabel {...FormLabelStyle}>Type Of Duty*</FormLabel>
                <ChakraSelect
                  name="typeOfDuty"
                  value={formData.typeOfDuty || ""}
                  onChange={handleInputChange}
                  {...SubInputStyle}
                >
                  <option value="" disabled>
                    Type Of Duty
                  </option>
                  <option value="Duty-Free">Duty-Free</option>
                  <option value="With-Duty">With-Duty</option>
                </ChakraSelect>
              </Box>
              <Box>
                <FormLabel {...FormLabelStyle}>Type Of Import*</FormLabel>
                <ChakraSelect
                  name="typeOfImport"
                  value={formData.typeOfImport || ""}
                  onChange={handleInputChange}
                  {...SubInputStyle}
                >
                  <option value="" disabled>
                    Type Of Import
                  </option>
                  <option value="Direct Import">Direct Import</option>
                  <option value="High Sea Sale Import">
                    High Sea Sale Import
                  </option>
                </ChakraSelect>
              </Box>
            </Flex>
          </Flex>

          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Payment Term</FormLabel>
              <ChakraSelect
                name="paymentMode"
                value={formData.paymentMode || ""}
                onChange={handleInputChange}
                {...InputStyle}
              >
                <option value="" disabled>
                  Payment Term
                </option>
                <option value="Fixed Price">Fixed Price</option>
                <option value="Floating Price">Floating Price</option>
              </ChakraSelect>
            </Box>
            <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
          </Flex>

          {formData.paymentMode === "Fixed Price" && (
            <>
              {formData.fixedPriceRows.map((row, index) => (
                <Flex justifyContent={"space-around"} mt={2} mb={2} key={index}>
                  {index === 0 && (
                    <Flex justifyContent={"space-around"} mt={1} gap={12}>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                        <ChakraSelect
                          name="fixeditemName"
                          value={row.fixeditemName}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Quantity</FormLabel>
                        <Input
                          type="text"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Unit (Kg/MTS)</FormLabel>
                        <ChakraSelect
                          name="itemUnit"
                          value={row.itemUnit}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Unit
                          </option>
                          <option value="Kgs">Kgs</option>
                          <option value="MTS">MTS</option>
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Price</FormLabel>
                        <Input
                          type="text"
                          name="price"
                          value={row.price}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        />
                      </Box>
                    </Flex>
                  )}

                  {index !== 0 && (
                    <Flex justifyContent={"space-between"} mt={5} gap={12}>
                      <Box>
                        <ChakraSelect
                          name="fixeditemName"
                          value={row.fixeditemName}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        />
                      </Box>
                      <Box>
                        <ChakraSelect
                          name="itemUnit"
                          value={row.itemUnit}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Unit
                          </option>
                          <option value="Kgs">Kgs</option>
                          <option value="MTS">MTS</option>
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="price"
                          value={row.price}
                          onChange={(e) => handleFixedRowChange(index, e)}
                        />
                      </Box>
                    </Flex>
                  )}
                </Flex>
              ))}

              <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
                <Button
                  onClick={addFixedRow}
                  mt={5}
                  color="white"
                  backgroundColor="teal.400"
                  borderRadius="15"
                  _hover={{ backgroundColor: "green.200", color: "black" }}
                >
                  + Add Items
                </Button>
                <IconButton
                  onClick={removeFixedRow}
                  color="white"
                  backgroundColor="red"
                  borderRadius="15"
                  mt={5}
                  _hover={{ backgroundColor: "red.400", color: "black" }}
                  icon={<MdDeleteForever />}
                  aria-label="Remove"
                  isDisabled={formData.fixedPriceRows.length <= 1}
                />
              </Flex>
            </>
          )}

          {formData.paymentMode === "Floating Price" && (
            <>
              {formData.floatingPriceRows.map((row, index) => (
                <Flex justifyContent={"space-around"} mt={2} mb={2} key={index}>
                  {index === 0 && (
                    <Flex justifyContent={"space-around"} mt={1} gap={12}>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                        <ChakraSelect
                          type="text"
                          name="floatingitemName"
                          value={row.floatingitemName}
                          onChange={(e) => handleRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Date</FormLabel>
                        <Input
                          type="date"
                          name="date"
                          value={row.date}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>
                          Quantity in MTS
                        </FormLabel>
                        <Input
                          type="text"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Unit(Kg/MTS)</FormLabel>
                        <ChakraSelect
                          name="itemUnit"
                          value={row.itemUnit}
                          onChange={(e) => handleRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Unit
                          </option>
                          <option value="Kgs">Kgs</option>
                          <option value="MTS">MTS</option>
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>LME Price</FormLabel>
                        <Input
                          type="text"
                          name="lmePrice"
                          value={row.lmePrice}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Rate %</FormLabel>
                        <Input
                          type="text"
                          name="rate"
                          value={row.rate}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>
                          Final Price/MT
                        </FormLabel>
                        <Input
                          type="text"
                          name="finalPrice"
                          value={row.finalPrice}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                    </Flex>
                  )}

                  {index !== 0 && (
                    <Flex justifyContent={"space-between"} mt={5} gap={12}>
                      <Box>
                        <ChakraSelect
                          type="text"
                          name="floatingitemName"
                          value={row.floatingitemName}
                          onChange={(e) => handleRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <Input
                          type="date"
                          name="date"
                          value={row.date}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <ChakraSelect
                          name="itemUnit"
                          value={row.itemUnit}
                          onChange={(e) => handleRowChange(index, e)}
                        >
                          <option value="" disabled>
                            Item Unit
                          </option>
                          <option value="Kgs">Kgs</option>
                          <option value="MTS">MTS</option>
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="lmePrice"
                          value={row.lmePrice}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="rate"
                          value={row.rate}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="finalPrice"
                          value={row.finalPrice}
                          onChange={(e) => handleRowChange(index, e)}
                          focusBorderColor="teal.400"
                        />
                      </Box>
                    </Flex>
                  )}
                </Flex>
              ))}

              <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
                <Button
                  onClick={addRow}
                  mt={5}
                  color="white"
                  backgroundColor="teal.400"
                  borderRadius="15"
                  _hover={{ backgroundColor: "green.200", color: "black" }}
                >
                  + Add Floating Items
                </Button>
                <IconButton
                  onClick={removeRow}
                  color="white"
                  backgroundColor="red"
                  borderRadius="15"
                  mt={5}
                  _hover={{ backgroundColor: "red.400", color: "black" }}
                  icon={<MdDeleteForever />}
                  aria-label="Remove"
                  isDisabled={formData.floatingPriceRows.length <= 1}
                />
              </Flex>
            </>
          )}

          <Flex justifyContent={"center"} alignItems={"center"} mt={5}>
            <Box w={"85%"}>
              <FormLabel {...FormLabelStyle}>Remarks</FormLabel>
              <Textarea
                type="text"
                placeholder=""
                name="salesContractRemarks"
                value={formData.salesContractRemarks || ""}
                onChange={handleInputChange}
                focusBorderColor="teal.400"
              />
            </Box>
          </Flex>
          <Flex justifyContent={"flex-start"} mt={5} gap={2} wrap="wrap">
            <Button
              onClick={addAdvancePaymentRow}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              + Add Advance Payment Details
            </Button>
            <IconButton
              onClick={removeAdvancePaymentRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={formData.advancePaymentDetails.length <= 1}
            />
          </Flex>
          {formData.advancePaymentDetails.map((row, index) => (
            <Flex key={`advancePaymentRow-${index}`} mt={5}>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Sl No</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="slNo"
                  value={String(index + 1).padStart(2, "0")}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>Date</FormLabel>}
                <Input
                  type="date"
                  placeholder=""
                  name="date"
                  value={row.date}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                )}
                <ChakraSelect
                  name="bankName"
                  value={row.bankName}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                >
                  <option value="" disabled>
                    Bank Name
                  </option>
                  {bankNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transaction Ref No</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="transactionReferenceNo"
                  value={row.transactionReferenceNo}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Amount</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="amount"
                  value={row.amount}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                )}
                {/* <Input
                  type="text"
                  placeholder=""
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                /> */}
                <ChakraSelect
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    HSS Value
                  </option>
                  <option value="USD">USD</option>
                  <option value="INR">INR</option>
                  <option value="EUR">Euro</option>
                  <option value="QAR">Qatar Riyal</option>
                  <option value="SAR">Saudi Riyal</option>
                </ChakraSelect>
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="exchangeRates"
                  value={row.exchangeRates}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Amount In INR</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="amountInINR"
                  value={row.amountInINR}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Bank Charges</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="bankCharges"
                  value={row.bankCharges}
                  onChange={(e) => handleRowAdvancePaymentChange(index, e)}
                />
              </Box>
            </Flex>
          ))}

          {/* <Flex justifyContent={"flex-end"} mt={5}>
            <Button
              onClick={addAdvancePaymentRow}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
          </Flex> */}
          <Flex justifyContent={"start"} p={5}>
            <Heading
              textAlign={"start"}
              size={"md"}
              color={"teal.400"}
            ></Heading>
          </Flex>
        </FormControl>
      </Box>
    </Box>
  );

  const [editSingleContractData, setEditSingleContractData] = useState({
    salesContractNumber: singleContractData?.[0]?.salesContractNumber ?? "",
    salesContractDate: singleContractData?.[0]?.salesContractDate ?? "",
    exporterName: singleContractData?.[0]?.exporterName ?? "",
    salesContractContactPersonName:
      singleContractData?.[0]?.salesContractNumber ?? "",
    importingFirmName: singleContractData?.[0]?.importingFirmName ?? "",
    typeOfDuty: singleContractData?.[0]?.typeOfDuty ?? "",
    typeOfImport: singleContractData?.[0]?.typeOfImport ?? "",
    paymentMode: singleContractData?.[0]?.paymentMode ?? "",
    itemName: singleContractData?.[0]?.itemName ?? "",
    fixedQuantity: singleContractData?.[0]?.fixedQuantity ?? "",
    priceTerms: singleContractData?.[0]?.priceTerms ?? "",
    salesContractRemarks: singleContractData?.[0]?.salesContractRemarks ?? "",
    floatingPriceRows: [],
    advancePaymentDetails: [],
  });
  //console.log("editSingleContractData",editSingleContractData);
  const handleEditContractInputChange = (e) => {
    const { name, value } = e.target;
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0][name] = value;
      return newData;
    });
  };

  const handleEditFixedRowChange = (index, e) => {
    const { name, value } = e.target;
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0].fixedPriceRows[index][name] = value;
      return newData;
    });
  };

  const handleEditContractRowChange = (index, e) => {
    const { name, value } = e.target;
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0].floatingPriceRows[index][name] = value;
      return newData;
    });
  };

  const handleEditContractRowAdvancePaymentChange = (index, e) => {
    const { name, value } = e.target;
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0].advancePaymentDetails[index][name] = value;
      return newData;
    });
  };

  const addEditFixedPriceIteamRow = () => {
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0].fixedPriceRows.push({
        date: "",
        fixeditemName: "",
        quantity: "10",
        price: "",
        itemUnit: "",
      });
      return newData;
    });
  };

  const removeEditFixedPriceItemRow = () => {
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      if (newData[0].fixedPriceRows.length > 1) {
        newData[0].fixedPriceRows.pop();
      }
      return newData;
    });
  };
  

  const addEditContractRow = () => {
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0].floatingPriceRows.push({
        date: "",
        floatingitemName: "",
        quantity: "",
        lmePrice: "",
        rate: "",
        finalPrice: "",
      });
      return newData;
    });
  };

  const removeEditFloatingPriceItemRow = () => {
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      if (newData[0].floatingPriceRows.length > 1) {
        newData[0].floatingPriceRows.pop();
      }
      return newData;
    });
  };

  const addEditContractAdvancePaymentRow = () => {
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      newData[0].advancePaymentDetails.push({
        slNo: newData[0].advancePaymentDetails.length + 1,
        date: "",
        bankName: "",
        transactionReferenceNo: "",
        amount: "",
        currency: "",
        exchangeRates: "",
        amountInINR: "",
        bankCharges: "",
      });
      return newData;
    });
  };

  const removeEditContractAdvancePaymentRow = () => {
    setSingleContractData((prevData) => {
      const newData = [...prevData];
      // Ensure there is at least one row to remove
      if (newData[0].advancePaymentDetails.length > 1) {
        newData[0].advancePaymentDetails.pop();
      }
      return newData;
    });
  };
  

  const handleEditContractSave = async (_id) => {
    try {
      const data = { salesContractContactPersonName: "shaik 123" };
      console.log(singleContractData, "singleContractData");
      console.log(data, "data");
      const response = await axios.patch(
        `${backendBaseURL}/editSalesContractDetails/${_id}`,
        singleContractData[0]
      );
      if (response.status === 200) {
        // Handle success
        alert("Sales contract updated successfully");
        console.log("Sales contract updated successfully");
        setView("list");
      } else {
        // Handle error
        console.log("Error updating sales contract");
        alert("Error updating sales contract");
      }
    } catch (error) {
      console.error("Error updating sales contract:", error);
      alert("Error updating sales contract:", error);
    }
  };

  const renderEditForm = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} p={5}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => setView("list")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Edit Sales Contract {selectedSalesContractNumber}
          </Heading>
        </Flex>
        <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          // onClick={() => setView("add")}
          onClick={() => handleEditContractSave(singleContractData[0]._id)}
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          + Save Edited Sales Contract
        </Button>
      </Flex>
      <Box>
        <FormControl>
          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Sales Contract Number</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="salesContractNumber"
                value={singleContractData?.[0]?.salesContractNumber ?? ""}
                onChange={handleEditContractInputChange}
                {...InputStyle}
                isReadOnly
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Contract Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="salesContractDate"
                value={singleContractData?.[0]?.salesContractDate ?? ""}
                onChange={handleEditContractInputChange}
                {...InputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Name</FormLabel>
              <ChakraSelect
                name="exporterName"
                value={singleContractData?.[0]?.exporterName ?? ""}
                onChange={handleEditContractInputChange}
                {...InputStyle}
              >
                <option value="" disabled>
                  Exporter Name
                </option>

                {exporterNamesList.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </ChakraSelect>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Contact Person Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="salesContractContactPersonName"
                value={
                  singleContractData?.[0]?.salesContractContactPersonName ?? ""
                }
                onChange={handleEditContractInputChange}
                {...InputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Importing Company Name</FormLabel>
              <ChakraSelect
                name="importingFirmName"
                value={singleContractData[0]?.importingFirmName || ""}
                onChange={handleEditContractInputChange}
                {...InputStyle}
              >
                <option value="" disabled>
                  Importing Company Name
                </option>

                {importerFirmNamesList.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </ChakraSelect>
            </Box>
            <Flex justifyContent={"space-around"} gap={5}>
              <Box>
                <FormLabel {...FormLabelStyle}>Type Of Duty</FormLabel>
                <ChakraSelect
                  name="typeOfDuty"
                  value={singleContractData?.[0]?.typeOfDuty ?? ""}
                  onChange={handleEditContractInputChange}
                  {...SubInputStyle}
                >
                  <option value="" disabled>
                    Type Of Duty
                  </option>
                  <option value="Duty-Free">Duty-Free</option>
                  <option value="With-Duty">With-Duty</option>
                </ChakraSelect>
              </Box>
              <Box>
                <FormLabel {...FormLabelStyle}>Type Of Import</FormLabel>
                <ChakraSelect
                  name="typeOfImport"
                  value={singleContractData?.[0]?.typeOfImport ?? ""}
                  onChange={handleEditContractInputChange}
                  {...SubInputStyle}
                >
                  <option value="" disabled>
                    Type Of Import
                  </option>
                  <option value="Direct Import">Direct Import</option>
                  <option value="High Sea Sale Import">
                    High Sea Sale Import
                  </option>
                </ChakraSelect>
              </Box>
            </Flex>
          </Flex>

          <Flex justifyContent={"space-around"} mt={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Payment Term</FormLabel>
              <ChakraSelect
                name="paymentMode"
                value={singleContractData?.[0]?.paymentMode ?? ""}
                onChange={handleEditContractInputChange}
                {...InputStyle}
                disabled={true}
              >
                <option value="" disabled>
                  Payment Term
                </option>
                <option value="Fixed Price">Fixed Price</option>
                <option value="Floating Price">Floating Price</option>
              </ChakraSelect>
            </Box>
            <Box style={{ visibility: "hidden" }} {...InputStyle}></Box>
          </Flex>

          {singleContractData?.[0]?.paymentMode === "Fixed Price" && (
            <>
              {singleContractData?.[0]?.fixedPriceRows.map((data, index) => {
                return (
                  <>
                    <Flex justifyContent={"space-around"} mt={5}>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                        <ChakraSelect
                          name="fixeditemName"
                          value={
                            singleContractData?.[0]?.fixedPriceRows[index]
                              .fixeditemName ?? ""
                          }
                          onChange={(e) => handleEditFixedRowChange(index, e)}
                          {...(singleContractData?.[0]?.paymentMode ===
                          "Fixed Price"
                            ? MultiInputStyle
                            : InputStyle)}
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>
                          Quantity in (MTS)
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder=""
                          name="quantity"
                          value={
                            singleContractData?.[0]?.fixedPriceRows[index]
                              .quantity ?? ""
                          }
                          onChange={(e) => handleEditFixedRowChange(index, e)}
                          {...(singleContractData?.[0]?.paymentMode ===
                          "Fixed Price"
                            ? MultiInputStyle
                            : InputStyle)}
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Price</FormLabel>
                        <Input
                          type="text"
                          placeholder=""
                          name="price"
                          value={
                            singleContractData?.[0]?.fixedPriceRows[index]
                              .price ?? ""
                          }
                          onChange={(e) => handleEditFixedRowChange(index, e)}
                          {...MultiInputStyle}
                        />
                      </Box>
                    </Flex>
                  </>
                );
              })}

              <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
                <Button
                  onClick={addEditFixedPriceIteamRow}
                  mt={5}
                  color="white"
                  backgroundColor="teal.400"
                  borderRadius="15"
                  _hover={{ backgroundColor: "green.200", color: "black" }}
                >
                  + Add Items
                </Button>
                <IconButton
                  onClick={removeEditFixedPriceItemRow}
                  color="white"
                  backgroundColor="red"
                  borderRadius="15"
                  mt={5}
                  _hover={{ backgroundColor: "red.400", color: "black" }}
                  icon={<MdDeleteForever />}
                  aria-label="Remove"
                  isDisabled={singleContractData?.[0]?.fixedPriceRows.length <= 1}
                />
              </Flex>
            </>
          )}

          {singleContractData?.[0]?.paymentMode === "Floating Price" && (
            <>
              {singleContractData[0].floatingPriceRows.map((row, index) => (
                <Flex justifyContent={"space-around"} mt={2} mb={2} key={index}>
                  {index === 0 && (
                    <Flex justifyContent={"space-around"} mt={1} gap={12}>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                        <ChakraSelect
                          name="floatingitemName"
                          value={
                            singleContractData?.[0]?.floatingPriceRows[index]
                              .floatingitemName ?? ""
                          }
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Date</FormLabel>
                        <Input
                          type="date"
                          name="date"
                          value={row.date}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>
                          Quantity in MTS
                        </FormLabel>
                        <Input
                          type="text"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>LME Price</FormLabel>
                        <Input
                          type="text"
                          name="lmePrice"
                          value={row.lmePrice}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>Rate %</FormLabel>
                        <Input
                          type="text"
                          name="rate"
                          value={row.rate}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <FormLabel {...FormLabelStyle}>
                          Final Price/MT
                        </FormLabel>
                        <Input
                          type="text"
                          name="finalPrice"
                          value={row.finalPrice}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                    </Flex>
                  )}

                  {index !== 0 && (
                    <Flex justifyContent={"space-between"} mt={5} gap={12}>
                      <Box>
                        <ChakraSelect
                          name="floatingitemName"
                          value={
                            singleContractData?.[0]?.floatingPriceRows[index]
                              .floatingitemName ?? ""
                          }
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                        >
                          <option value="" disabled>
                            Item Name
                          </option>
                          {itemNamesList.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </ChakraSelect>
                      </Box>
                      <Box>
                        <Input
                          type="date"
                          name="date"
                          value={row.date}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="quantity"
                          value={row.quantity}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="lmePrice"
                          value={row.lmePrice}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="rate"
                          value={row.rate}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                      <Box>
                        <Input
                          type="text"
                          name="finalPrice"
                          value={row.finalPrice}
                          onChange={(e) =>
                            handleEditContractRowChange(index, e)
                          }
                          focusBorderColor="teal.400"
                        />
                      </Box>
                    </Flex>
                  )}
                </Flex>
              ))}

              <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
                <Button
                  onClick={addEditContractRow}
                  mt={5}
                  color="white"
                  backgroundColor="teal.400"
                  borderRadius="15"
                  _hover={{ backgroundColor: "green.200", color: "black" }}
                >
                  + Add Floating Items
                </Button>
                <IconButton
                  onClick={removeEditFloatingPriceItemRow}
                  color="white"
                  backgroundColor="red"
                  borderRadius="15"
                  mt={5}
                  _hover={{ backgroundColor: "red.400", color: "black" }}
                  icon={<MdDeleteForever />}
                  aria-label="Remove"
                  isDisabled={singleContractData[0].floatingPriceRows.length <= 1}
                />
              </Flex>
            </>
          )}

          <Flex justifyContent={"center"} alignItems={"center"} mt={5}>
            <Box w={"85%"}>
              <FormLabel {...FormLabelStyle}>Remarks</FormLabel>
              <Textarea
                type="text"
                placeholder=""
                name="salesContractRemarks"
                value={singleContractData?.[0]?.salesContractRemarks ?? ""}
                onChange={handleEditContractInputChange}
                focusBorderColor="teal.400"
              />
            </Box>
          </Flex>
          <Flex justifyContent={"flex-start"} mt={5} gap={2} wrap="wrap">
            <Button
              onClick={addEditContractAdvancePaymentRow}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              + Add Advance Payment Details
            </Button>
            <IconButton
                  onClick={removeEditContractAdvancePaymentRow}
                  color="white"
                  backgroundColor="red"
                  borderRadius="15"
                  _hover={{ backgroundColor: "red.400", color: "black" }}
                  icon={<MdDeleteForever />}
                  aria-label="Remove"
                  isDisabled={singleContractData[0].advancePaymentDetails.length <= 1}
                />
          </Flex>
          {singleContractData[0]?.advancePaymentDetails?.map((row, index) => (
            <Flex key={`advancePaymentRow-${index}`} mt={5}>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Sl No</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="slNo"
                  value={String(index + 1).padStart(2, "0")}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>Date</FormLabel>}
                <Input
                  type="date"
                  placeholder=""
                  name="date"
                  value={row.date}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                )}
                <ChakraSelect
                  name="bankName"
                  value={row.bankName}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Bank Name
                  </option>
                  {bankNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transaction Ref No</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="transactionReferenceNo"
                  value={row.transactionReferenceNo}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Amount</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="amount"
                  value={row.amount}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="currency"
                  value={row.currency}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="exchangeRates"
                  value={row.exchangeRates}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Amount In INR</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="amountInINR"
                  value={row.amountInINR}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Bank Charges</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder=""
                  name="bankCharges"
                  value={row.bankCharges}
                  onChange={(e) =>
                    handleEditContractRowAdvancePaymentChange(index, e)
                  }
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}

          
        </FormControl>
      </Box>
    </Box>
  );

  const renderInfo = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} p={5}>
        <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
          Sales Contract Info
        </Heading>
        <Button
          color="white"
          backgroundColor="teal.400"
          borderRadius="15"
          onClick={() => setView("list")}
          _hover={{ backgroundColor: "green.200", color: "black" }}
        >
          Back to List
        </Button>
      </Flex>
      {/* Sales Contract Info Content Here */}
    </Box>
  );

  // Rendering Each Tabs Start Here //

  const [shipmentWiseData, setShipmentWiseData] = useState({});
  const [documentsStatusData, setDocumentsStatusData] = useState({});
  const [paymentStatusData, setPaymentStatusData] = useState({});
  const [customerClearingStatusData, setCustomerClearingStatusData] = useState(
    {}
  );
  const [shipmentSummaryData, setShipmentSummaryData] = useState({});
  const [costingData, setCostingData] = useState({});

  const [value, setValue] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (selectedOption) => {
    setValue(selectedOption);
    setShipmentWiseData((prevData) => ({
      ...prevData,
      shipmentcountry: selectedOption.label,
    }));
  };

  const handleInputChangeShipmentWise = (e) => {
    const { name, value } = e.target;
    setShipmentWiseData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChangePaymentStatus = (e) => {
    const { name, value } = e.target;
    setPaymentStatusData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChangeCustomClearingStatus = (e) => {
    const { name, value } = e.target;
    setCustomerClearingStatusData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [rows, setRows] = useState([
    {
      itemName: "",
      quantity: "",
      hsn: "",
      rate: "",
      currency: "",
      invoiceValue: "",
      difference: "",
    },
  ]);

  const handleInputChangeRows = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], [name]: value };
    setRows(newRows);
  };

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        itemName: "",
        quantity: "",
        hsn: "",
        rate: "",
        exchangeRate: "",
        hssValueUSD: "",
        hssValueINR: "",
        currency: "",
        invoiceValue: "",
        difference: "",
      },
    ]);
  };

  const removeNewRow = () => {
    setRows(rows.slice(0, -1));
  };

  const [paymentStatusRows, setPaymentStatusRows] = useState([
    {
      modeOfPayment: "",
      paymentDate: "",
      paymentBankName: "",
      transactionRefNo: "",
      paymentAmount: "",
      paymentCurrency: "",
      exchangeRate: "",
      amountInINR: "",
      bankCharges: "",
      creditPeriod: "",
      paymentDueDate: "",
    },
  ]);

  const handleInputChangePaymentStatusRows = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...paymentStatusRows];
    newRows[index] = { ...newRows[index], [name]: value };
    setPaymentStatusRows(newRows);
  };

  const addNewPaymentStatusRow = () => {
    setPaymentStatusRows([
      ...paymentStatusRows,
      {
        modeOfPayment: "",
        paymentDate: "",
        paymentBankName: "",
        transactionRefNo: "",
        paymentAmount: "",
        paymentCurrency: "",
        exchangeRate: "",
        amountInINR: "",
        bankCharges: "",
        creditPeriod: "",
        paymentDueDate: "",
      },
    ]);
  };

  const removeNewPaymentStatusRow = () => {
    setPaymentStatusRows(paymentStatusRows.slice(0, -1));
  };

  const handleSave = async () => {
    const allData = {
      salesContractNumber: formData.salesContractNumber,
      shipmentWiseData: {
        ...shipmentWiseData,
        exporterName: formData.exporterName,
        quantityInMTS: formData.fixedQuantity,
        typeOfDuty: formData.typeOfDuty,
        hssName: formData.exporterName,
      },
      rows,
      documentsStatusData: {
        draftStatusFromShipper,
        originalStatusFromShipper,
        draftStatusToClearingAgent,
        originalStatusToClearingAgent,
      },
      rowsShipper,
      rowsDeliver,
      paymentStatusRows,
      paymentStatusData,
      customerClearingStatusData,
      rowsWeightLevel1,
      rowsWeightLevel2,
      rowsWeightLevel3,
      totalsWeightLevel1,
      totalsWeightLevel2,
      totalsWeightLevel3,
      // shipmentSummaryData,
      // costingData,
    };

    try {
      await axios.post(`${backendBaseURL}/postShippingDetails`, allData);
      alert("Data saved successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data");
    }
  };

  const renderShipmentWise = () => (
    <Box>
      <FormControl>
        <Flex justifyContent={"space-around"} mt={5}>
          {formData.typeOfImport === "Direct Import" && (
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="exporterName"
                value={shipmentWiseData.exporterName || formData.exporterName}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
                isReadOnly
              />
            </Box>
          )}
          {formData.typeOfImport === "High Sea Sale Import" && (
            <Box>
              <FormLabel {...FormLabelStyle}>HSS Exporter Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="hssName"
                value={shipmentWiseData.hssName || formData.exporterName}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
          )}

          <Box {...InputStyle}>
            <FormLabel {...FormLabelStyle}>Country Of Origin</FormLabel>
            <Select options={options} value={value} onChange={changeHandler} />
          </Box>
        </Flex>

        {formData.typeOfImport === "High Sea Sale Import" && (
          <Flex justifyContent={"space-around"} mt={2}>
            <Box>
              <FormLabel {...FormLabelStyle}>Shipper Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="shipperName"
                value={shipmentWiseData.shipperName || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>HSS Seller Invoice No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="hssSellerInvoiceNo"
                value={shipmentWiseData.hssSellerInvoiceNo || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
          </Flex>
        )}

        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Type Of Duty</FormLabel>
            <ChakraSelect
              name="typeOfDuty"
              value={shipmentWiseData.typeOfDuty || formData.typeOfDuty}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Type Of Duty
              </option>
              <option value="Duty-Free">Duty-Free</option>
              <option value="With-Duty">With-Duty</option>
            </ChakraSelect>
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>Shipping Line Name</FormLabel>
            {/* <Input
              type="text"
              placeholder=""
              name="shippingLine"
              value={shipmentWiseData.shippingLine || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            /> */}
            <ChakraSelect
              name="shippingLine"
              value={shipmentWiseData.shippingLine || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Shipping Line Name
              </option>
              {shippingLinesList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </ChakraSelect>
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Bill Of Lending Number</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="lendingNo"
              value={shipmentWiseData.lendingNo || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>
              Estimated Time of Arrival (ETA)
            </FormLabel>
            <Input
              type="date"
              placeholder=""
              name="eta"
              value={shipmentWiseData.eta || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Final Delivery Port</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="finalDeliveryPort"
              value={shipmentWiseData.finalDeliveryPort || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>Clearing House Agent Name</FormLabel>

            <ChakraSelect
              name="clearingHouseAgentName"
              value={shipmentWiseData.clearingHouseAgentName || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Clearing House Agent Name
              </option>
              {clearingAgentsList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </ChakraSelect>
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Quantity (In MTS)</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="quantityInMTS"
              value={shipmentWiseData.quantityInMTS || formData.fixedQuantity}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
              isReadOnly
            />
          </Box>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Invoice No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="exporterInvoiceNo"
                value={shipmentWiseData.exporterInvoiceNo || ""}
                onChange={handleInputChangeShipmentWise}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Invoice Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="exporterInvoiceDate"
                value={shipmentWiseData.exporterInvoiceDate || ""}
                onChange={handleInputChangeShipmentWise}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>

        <Box>
          {rows.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                )}
                <ChakraSelect
                  name="itemName"
                  value={row.itemName || formData.itemName}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Item Name
                  </option>
                  {itemNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Quantity</FormLabel>
                )}
                <Input
                  type="text"
                  name="quantity"
                  value={row.quantity}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>HSN</FormLabel>}
                <Input
                  type="text"
                  name="hsn"
                  value={row.hsn}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>Rate</FormLabel>}
                <Input
                  type="text"
                  name="rate"
                  value={row.rate}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                )}
                {/* <Input
                  type="text"
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                /> */}
                <ChakraSelect
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Currency
                  </option>
                  <option value="USD">USD</option>
                  <option value="INR">INR</option>
                  <option value="EUR">Euro</option>
                  <option value="QAR">Qatar Riyal</option>
                  <option value="SAR">Saudi Riyal</option>
                </ChakraSelect>
              </Box>
              {formData.typeOfImport === "High Sea Sale Import" && (
                <>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    )}
                    <Input
                      type="text"
                      name="exchangeRate"
                      value={row.exchangeRate}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>HSS Value</FormLabel>
                    )}
                    <Input
                      type="text"
                      name="hssValueUSD"
                      value={row.hssValueUSD}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>
                        HSS Value In INR
                      </FormLabel>
                    )}
                    <Input
                      type="text"
                      name="hssValueINR"
                      value={row.hssValueINR}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                </>
              )}
              {/* <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                )}
                <Input
                  type="text"
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box> */}
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Exporter Invoice Value
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceValue"
                  value={row.invoiceValue}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Difference</FormLabel>
                )}
                <Input
                  type="text"
                  name="difference"
                  value={row.difference}
                  onChange={(e) => handleInputChange(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRow}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rows.length <= 1}
            />
          </Flex>
        </Box>
      </FormControl>
    </Box>
  );

  const initialDocuments = [
    "Loading Picture",
    "Bill of Lading",
    "Certificate of Origin",
    "Commercial Invoice",
    "Packing List",
    "Test Analysis Report",
    "Self-Declaration",
    "Form-I",
    "Flow Chart",
  ];

  const [selectedClearingDocuments, setSelectedClearingDocuments] = useState(
    []
  );

  const handleCheckboxClearingChange = (doc) => {
    setSelectedClearingDocuments((prev) =>
      prev.includes(doc) ? prev.filter((d) => d !== doc) : [...prev, doc]
    );
  };

  const [draftStatusFromShipper, setDraftStatusFromShipper] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );
  const [originalStatusFromShipper, setOriginalStatusFromShipper] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: "" }), {})
  );
  const [
    originalStatusFromShipperChecked,
    setOriginalStatusFromShipperChecked,
  ] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );
  const [draftStatusToClearingAgent, setDraftStatusToClearingAgent] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );
  const [originalStatusToClearingAgent, setOriginalStatusToClearingAgent] =
    useState(
      initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: "" }), {})
    );
  const [
    originalStatusToClearingAgentChecked,
    setOriginalStatusToClearingAgentChecked,
  ] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );

  const handleDraftChangeFromShipper = (doc) => {
    setDraftStatusFromShipper((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleDraftChangeToClearingAgent = (doc) => {
    setDraftStatusToClearingAgent((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleOriginalChange = (setOriginalStatus, doc, value) => {
    setOriginalStatus((prevState) => ({
      ...prevState,
      [doc]: value,
    }));
  };

  const handleOriginalCheckedChange = (setOriginalStatusChecked, doc) => {
    setOriginalStatusChecked((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleSelectAllDraft = (draftStatus, setDraftStatus) => {
    const allSelected = Object.values(draftStatus).every((status) => status);
    const newState = initialDocuments.reduce(
      (acc, doc) => ({ ...acc, [doc]: !allSelected }),
      {}
    );
    setDraftStatus(newState);
  };

  const [selectedShipperDocuments, setSelectedShipperDocuments] = useState([]);

  // const [selectedTCAHDocuments, setSelectedTCAHDocuments] = useState([]);

  // const handleCheckboxTCAHChange = (doc) => {
  //   setSelectedTCAHDocuments((prev) =>
  //     prev.includes(doc) ? prev.filter((d) => d !== doc) : [...prev, doc]
  //   );
  // };

  const [rowsShipper, setRowsShipper] = useState([
    {
      selectedDocuments: [],
      location: "",
      companyName: "",
      trackingDetails: "",
      receivingStatus: "",
      refNo: "",
    },
  ]);
  const handleCheckboxShipperChange = (index, doc) => {
    setRowsShipper((prev) => {
      const updatedRows = [...prev];
      const { selectedDocuments } = updatedRows[index];
      updatedRows[index].selectedDocuments = selectedDocuments.includes(doc)
        ? selectedDocuments.filter((d) => d !== doc)
        : [...selectedDocuments, doc];
      return updatedRows;
    });
  };

  const handleInputShipperChange = (index, field, value) => {
    setRowsShipper((prev) => {
      const updatedRows = [...prev];
      updatedRows[index][field] = value;
      return updatedRows;
    });
  };

  const addShipperRow = () => {
    setRowsShipper([
      ...rowsShipper,
      {
        selectedDocuments: [],
        location: "",
        companyName: "",
        trackingDetails: "",
        receivingStatus: "",
        refNo: "",
      },
    ]);
  };

  const removeShipperRow = () => {
    setRowsShipper(rowsShipper.slice(0, -1));
  };

  const [rowsDeliver, setRowsDeliver] = useState([
    {
      selectedDocuments: [],
      chaName: "",
      location: "",
      trackingDetails: "",
      deliveryStatus: "",
    },
  ]);
  const handleCheckboxDeliverChange = (index, doc) => {
    setRowsDeliver((prev) => {
      const updatedRows = [...prev];
      const { selectedDocuments } = updatedRows[index];
      updatedRows[index].selectedDocuments = selectedDocuments.includes(doc)
        ? selectedDocuments.filter((d) => d !== doc)
        : [...selectedDocuments, doc];
      return updatedRows;
    });
  };

  const handleInputDeliverChange = (index, field, value) => {
    setRowsDeliver((prev) => {
      const updatedRows = [...prev];
      updatedRows[index][field] = value;
      return updatedRows;
    });
  };

  const addDeliverRow = () => {
    setRowsDeliver([
      ...rowsDeliver,
      {
        selectedDocuments: [],
        chaName: "",
        location: "",
        trackingDetails: "",
        deliveryStatus: "",
      },
    ]);
  };

  const removeDeliverRow = () => {
    setRowsDeliver(rowsDeliver.slice(0, -1));
  };

  const renderDocumentStatus = () => (
    <Box p={4} overflowX="auto">
      <Flex justifyContent={"flex-start"}>
        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
          mb={5}
        >
          Document Status
        </Heading>
      </Flex>
      <Table variant="simple" size="sm" border={"1px solid gray"}>
        <Thead border={"1px solid gray"}>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}></Th>
            <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
              Documents Receiving Status from Shipper
            </Th>
            <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
              Documents Giving Status to Clearing Agent
            </Th>
          </Tr>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}>
              Documents Name
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Draft Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Original Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Draft Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Original Documents Status
            </Th>
          </Tr>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}></Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(draftStatusFromShipper).every(
                  (status) => status
                )}
                onChange={() =>
                  handleSelectAllDraft(
                    draftStatusFromShipper,
                    setDraftStatusFromShipper
                  )
                }
              />
              <span style={{ marginLeft: 10 }}>Select All </span>
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(
                  originalStatusFromShipperChecked
                ).every((status) => status)}
                onChange={() =>
                  handleSelectAllDraft(
                    originalStatusFromShipperChecked,
                    setOriginalStatusFromShipperChecked
                  )
                }
                // isChecked={false}
                // isReadOnly
              />
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(draftStatusToClearingAgent).every(
                  (status) => status
                )}
                onChange={() =>
                  handleSelectAllDraft(
                    draftStatusToClearingAgent,
                    setDraftStatusToClearingAgent
                  )
                }
              />
              <span style={{ marginLeft: 10 }}>Select All </span>
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(
                  originalStatusToClearingAgentChecked
                ).every((status) => status)}
                onChange={() =>
                  handleSelectAllDraft(
                    originalStatusToClearingAgentChecked,
                    setOriginalStatusToClearingAgentChecked
                  )
                }
                // isChecked={false}
                // isReadOnly
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {initialDocuments.map((doc) => (
            <Tr key={doc} border={"1px solid gray"}>
              <Td border={"1px solid gray"}>{doc}</Td>
              <Td border={"1px solid gray"}>
                <Checkbox
                  isChecked={draftStatusFromShipper[doc]}
                  onChange={() => handleDraftChangeFromShipper(doc)}
                />
              </Td>
              <Td border={"1px solid gray"}>
                <Flex justifyContent={"start"} gap={2}>
                  <Checkbox
                    isChecked={originalStatusFromShipperChecked[doc]}
                    onChange={() =>
                      handleOriginalCheckedChange(
                        setOriginalStatusFromShipperChecked,
                        doc
                      )
                    }
                    style={{ marginLeft: 10 }}
                  />
                  <Input
                    type="date"
                    value={originalStatusFromShipper[doc]}
                    onChange={(e) =>
                      handleOriginalChange(
                        setOriginalStatusFromShipper,
                        doc,
                        e.target.value
                      )
                    }
                  />
                </Flex>
              </Td>
              <Td border={"1px solid gray"}>
                <Checkbox
                  isChecked={draftStatusToClearingAgent[doc]}
                  onChange={() => handleDraftChangeToClearingAgent(doc)}
                />
              </Td>
              <Td border={"1px solid gray"}>
                <Flex justifyContent={"start"} gap={2}>
                  <Checkbox
                    isChecked={originalStatusToClearingAgentChecked[doc]}
                    onChange={() =>
                      handleOriginalCheckedChange(
                        setOriginalStatusToClearingAgentChecked,
                        doc
                      )
                    }
                    style={{ marginLeft: 10 }}
                  />
                  <Input
                    type="date"
                    value={originalStatusToClearingAgent[doc]}
                    onChange={(e) =>
                      handleOriginalChange(
                        setOriginalStatusToClearingAgent,
                        doc,
                        e.target.value
                      )
                    }
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Box mt={8}>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Original Documents Tracking from Shipper
          </Heading>
        </Flex>

        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th border={"1px solid gray"}>From Shipper</Th>
              <Th border={"1px solid gray"}>Courier Delivery Location</Th>
              <Th border={"1px solid gray"}>Courier Company Name</Th>
              <Th border={"1px solid gray"}>Tracking Details</Th>
              <Th border={"1px solid gray"}>Documents Receiving Status</Th>
              <Th border={"1px solid gray"}>
                Lodgement Status in Bank Ref No.
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowsShipper.map((row, index) => (
              <Tr key={index} border={"1px solid gray"}>
                <Td border={"1px solid gray"}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width={200}
                    >
                      <Box
                        as="span"
                        display="block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {row.selectedDocuments.length > 0
                          ? row.selectedDocuments.join(", ")
                          : "Complete Set of Documents"}
                      </Box>
                    </MenuButton>
                    <MenuList>
                      {initialDocuments.map((doc) => (
                        <MenuItem key={doc} closeOnSelect={false}>
                          <Checkbox
                            isChecked={row.selectedDocuments.includes(doc)}
                            onChange={() =>
                              handleCheckboxShipperChange(index, doc)
                            }
                            mr={2}
                          >
                            {doc}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Location"
                    value={row.location}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "location",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Company Name"
                    value={row.companyName}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "companyName",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Tracking Details"
                    value={row.trackingDetails}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "trackingDetails",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    type="date"
                    value={row.receivingStatus}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "receivingStatus",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Ref No."
                    value={row.refNo}
                    onChange={(e) =>
                      handleInputShipperChange(index, "refNo", e.target.value)
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt={4} justifyContent="flex-end" gap={2} wrap="wrap">
          <Button
            onClick={addShipperRow}
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Shipper
          </Button>
          <IconButton
            onClick={removeShipperRow}
            color="white"
            backgroundColor="red"
            borderRadius="15"
            _hover={{ backgroundColor: "red.400", color: "black" }}
            icon={<MdDeleteForever />}
            aria-label="Remove"
            isDisabled={rowsShipper.length <= 1}
          />
        </Flex>
      </Box>

      <Box mt={8}>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Original Documents Tracking to Clearing Agent House
          </Heading>
        </Flex>

        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th border={"1px solid gray"}>From Shipper</Th>
              <Th border={"1px solid gray"}>CHA Name</Th>
              <Th border={"1px solid gray"}>Location</Th>
              <Th border={"1px solid gray"}>Tracking Details</Th>
              <Th border={"1px solid gray"}>Documents Delivery Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowsDeliver.map((row, index) => (
              <Tr key={index} border={"1px solid gray"}>
                <Td border={"1px solid gray"}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width={200}
                    >
                      <Box
                        as="span"
                        display="block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {row.selectedDocuments.length > 0
                          ? row.selectedDocuments.join(", ")
                          : "Complete Set of Documents"}
                      </Box>
                    </MenuButton>
                    <MenuList>
                      {initialDocuments.map((doc) => (
                        <MenuItem key={doc} closeOnSelect={false}>
                          <Checkbox
                            isChecked={row.selectedDocuments.includes(doc)}
                            onChange={() =>
                              handleCheckboxDeliverChange(index, doc)
                            }
                            mr={2}
                          >
                            {doc}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="CHA Name"
                    value={row.chaName}
                    onChange={(e) =>
                      handleInputDeliverChange(index, "chaName", e.target.value)
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Location"
                    value={row.location}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "location",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Tracking Details"
                    value={row.trackingDetails}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "trackingDetails",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    type="date"
                    value={row.deliveryStatus}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "deliveryStatus",
                        e.target.value
                      )
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt={4} justifyContent="flex-end" gap={2} wrap="wrap">
          <Button
            onClick={addDeliverRow}
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Deliver
          </Button>

          <IconButton
            onClick={removeDeliverRow}
            color="white"
            backgroundColor="red"
            borderRadius="15"
            _hover={{ backgroundColor: "red.400", color: "black" }}
            icon={<MdDeleteForever />}
            aria-label="Remove"
            isDisabled={rowsDeliver.length <= 1}
          />
        </Flex>
      </Box>
    </Box>
  );

  const renderPaymentStatus = () => (
    <Box>
      <FormControl>
        <Box>
          {paymentStatusRows.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              flexDirection={"column"}
              key={index}
            >
              <Heading
                as="h6"
                size="xs"
                textAlign={"start"}
                textDecoration={"underline"}
              >
                Payment {index + 1}
              </Heading>
              <Flex justifyContent={"space-around"} mt={5}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Payment Term</FormLabel>
                    <ChakraSelect
                      name="modeOfPayment"
                      value={row.modeOfPayment || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    >
                      <option value="" disabled>
                        Payment Term
                      </option>
                      <option value="Cash">Cash</option>
                      <option value="TT">TT</option>
                      <option value="Buyer Credit">Buyer Credit</option>
                      <option value="LC">LC</option>
                      <option value="RTGS">RTGS</option>
                      <option value="Import Collection Bill">
                        Import Collection Bill
                      </option>
                    </ChakraSelect>
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Date</FormLabel>
                    <Input
                      type="date"
                      placeholder=""
                      name="paymentDate"
                      value={row.paymentDate || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                    {/* <Input
                      type="text"
                      placeholder=""
                      name="paymentBankName"
                      value={row.paymentBankName || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />*/}
                    <ChakraSelect
                      name="paymentBankName"
                      value={row.paymentBankName || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    >
                      <option value="" disabled>
                        Bank Name
                      </option>
                      {bankNamesList.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </ChakraSelect>
                  </Box>

                  <Box>
                    <FormLabel {...FormLabelStyle}>
                      Transaction Reference No
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="transactionRefNo"
                      value={row.transactionRefNo || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex justifyContent={"space-around"} mt={2}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Amount</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="paymentAmount"
                      value={row.paymentAmount || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                    {/* <Input
                      type="text"
                      placeholder=""
                      name="paymentCurrency"
                      value={row.paymentCurrency || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    /> */}
                    <ChakraSelect
                      name="paymentCurrency"
                      value={row.paymentCurrency || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                      focusBorderColor="teal.400"
                    >
                      <option value="" disabled>
                        HSS Value
                      </option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="EUR">Euro</option>
                      <option value="QAR">Qatar Riyal</option>
                      <option value="SAR">Saudi Riyal</option>
                    </ChakraSelect>
                  </Box>
                </Flex>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="exchangeRate"
                      value={row.exchangeRate || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Amount In INR</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="amountInINR"
                      value={row.amountInINR || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex justifyContent={"space-around"} mt={2}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Bank Charges</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="bankCharges"
                      value={row.bankCharges || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Credit Period</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="creditPeriod"
                      value={row.creditPeriod || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
                <Box>
                  <FormLabel {...FormLabelStyle}>Due Date</FormLabel>
                  <Input
                    type="date"
                    placeholder=""
                    name="paymentDueDate"
                    value={row.paymentDueDate || ""}
                    onChange={(e) =>
                      handleInputChangePaymentStatusRows(e, index)
                    }
                    {...InputStyle}
                  />
                </Box>
              </Flex>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewPaymentStatusRow}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewPaymentStatusRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={paymentStatusRows.length <= 1}
            />
          </Flex>
        </Box>
        <TableContainer>
          <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
            <Thead>
              <Tr boxShadow={"sm"}>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "start", color: "teal" }}
                >
                  Particulars
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Currency
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Amount
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Remark
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  {formData.typeOfImport === "High Sea Sale Import"
                    ? "HSS Invoice Value"
                    : "Shipper Invoice Value"}
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceCurrency"
                    value={paymentStatusData.shipperInvoiceCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceAmount"
                    value={paymentStatusData.shipperInvoiceAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceRemarks"
                    value={paymentStatusData.shipperInvoiceRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Add: Other Amount
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountCurrency"
                    value={paymentStatusData.otherAmountCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountAmount"
                    value={paymentStatusData.otherAmountAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountRemarks"
                    value={paymentStatusData.otherAmountRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Amount Paid
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountCurrency"
                    value={paymentStatusData.lessAmountCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountAmount"
                    value={paymentStatusData.lessAmountAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountRemarks"
                    value={paymentStatusData.lessAmountRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Credit Note
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditCurrency"
                    value={paymentStatusData.lessCreditCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditAmount"
                    value={paymentStatusData.lessCreditAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditRemarks"
                    value={paymentStatusData.lessCreditRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Claims
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimCurrency"
                    value={paymentStatusData.lessClaimCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimAmount"
                    value={paymentStatusData.lessClaimAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimRemarks"
                    value={paymentStatusData.lessClaimRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Box>
            {paginationData.length === 0 && (
              <Alert
                status="info"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={4}
              >
                <AlertIcon boxSize={8} />
                No Record Found
              </Alert>
            )}
          </Box>
        </TableContainer>
      </FormControl>
    </Box>
  );

  const [rowsCCS, setRowsCCS] = useState([
    {
      dispatchFrom: "",
      dispatchTo: "",
      transporterName: "",
      transporterQty: "",
      transporterRate: "",
    },
  ]);

  const handleInputChangeRowsCCS = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsCCS];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsCCS(newRows);
  };

  const addNewRowCCS = () => {
    setRowsCCS([
      ...rowsCCS,
      {
        dispatchFrom: "",
        dispatchTo: "",
        transporterName: "",
        transporterQty: "",
        transporterRate: "",
      },
    ]);
  };

  const removeNewRowCCS = () => {
    setRowsCCS(rowsCCS.slice(0, -1));
  };

  const renderCustomClearingStatus = () => (
    <Box>
      <FormControl>
        <Flex justifyContent={"space-around"} mt={5}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Check List
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="checkListReceivedDate"
                value={customerClearingStatusData.checkListReceivedDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Approved</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="checkListApprovedDate"
                value={customerClearingStatusData.checkListApprovedDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Approved By</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="checkListApprovedBy"
                value={customerClearingStatusData.checkListApprovedBy || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Bill Of Entry
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>BE No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="beNo"
                value={customerClearingStatusData.beNo || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>BE Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="beDate"
                value={customerClearingStatusData.beDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>BE Payment</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="bePaymentDate"
                value={customerClearingStatusData.bePaymentDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Seal Photo
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="sealPhotoDate"
                value={customerClearingStatusData.sealPhotoDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Remark</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="sealPhotoRemarks"
                value={customerClearingStatusData.sealPhotoRemarks || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...InputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                CFS Weight
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="cfsWeightDate"
                value={customerClearingStatusData.cfsWeightDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Remark</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="cfsWeightRemarks"
                value={customerClearingStatusData.cfsWeightRemarks || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...InputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Box mt={25}>
          {rowsCCS.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch From</FormLabel>
                )}
                <Input
                  type="text"
                  name="dispatchFrom"
                  value={row.dispatchFrom}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch To</FormLabel>
                )}
                <Input
                  type="text"
                  name="dispatchTo"
                  value={row.dispatchTo}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transporter Name</FormLabel>
                )}
                <ChakraSelect
                  name="transporterName"
                  value={row.transporterName}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                  {...MiniMultiInputStyle}
                >
                  <option value="" disabled>
                    Transporter Name
                  </option>
                  {transporterNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>

              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Tranporter Quantity</FormLabel>
                )}
                <Input
                  type="text"
                  name="transporterQty"
                  value={row.transporterQty}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transporter Rate</FormLabel>
                )}
                <Input
                  type="text"
                  name="transporterRate"
                  value={row.transporterRate}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowCCS}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowCCS}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsCCS.length <= 1}
            />
          </Flex>
        </Box>
      </FormControl>
    </Box>
  );

  const [rowsWeightLevel1, setRowsWeightLevel1] = useState([
    {
      containerNumber: "",
      noOfPackage: "",
      invoiceNetWeight: "",
      emptyContainerWeight: "",
      cfsWeight1: "",
      cfsWeight2: "",
      averageWeight3: "",
    },
  ]);

  const [totalsWeightLevel1, setTotalsWeightLevel1] = useState({
    noOfPackage: 0,
    invoiceNetWeight: 0,
    emptyContainerWeight: 0,
    cfsWeight1: 0,
    cfsWeight2: 0,
    averageWeight3: 0,
  });

  const handleInputChangeRowsWeightLevel1 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel1];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsWeightLevel1(newRows);
    calculateTotals(newRows);
  };

  const calculateTotals = (rows) => {
    const newTotals = rows.reduce(
      (acc, row) => {
        acc.noOfPackage += parseFloat(row.noOfPackage) || 0;
        acc.invoiceNetWeight += parseFloat(row.invoiceNetWeight) || 0;
        acc.emptyContainerWeight += parseFloat(row.emptyContainerWeight) || 0;
        acc.cfsWeight1 += parseFloat(row.cfsWeight1) || 0;
        acc.cfsWeight2 += parseFloat(row.cfsWeight2) || 0;
        acc.averageWeight3 += parseFloat(row.averageWeight3) || 0;
        return acc;
      },
      {
        noOfPackage: 0,
        invoiceNetWeight: 0,
        emptyContainerWeight: 0,
        cfsWeight1: 0,
        cfsWeight2: 0,
        averageWeight3: 0,
      }
    );
    setTotalsWeightLevel1(newTotals);
  };

  const addNewRowWeightLevel1 = () => {
    setRowsWeightLevel1([
      ...rowsWeightLevel1,
      {
        containerNumber: "",
        noOfPackage: "",
        invoiceNetWeight: "",
        emptyContainerWeight: "",
        cfsWeight1: "",
        cfsWeight2: "",
        averageWeight3: "",
      },
    ]);
  };

  const removeNewRowWeightLevel1 = () => {
    setRowsWeightLevel1(rowsWeightLevel1.slice(0, -1));
  };

  const [rowsWeightLevel2, setRowsWeightLevel2] = useState([
    {
      vehicleNumber: "",
      noOfPackageLoad: "",
      portLoadingWeight1: "",
      portLoadingWeight2: "",
      averageWeight3: "",
      dispatchDate: "",
      ewayBillNo: "",
    },
  ]);

  const [totalsWeightLevel2, setTotalsWeightLevel2] = useState({
    noOfPackageLoad: 0,
    portLoadingWeight1: 0,
    portLoadingWeight2: 0,
    averageWeight3: 0,
  });

  const handleInputChangeRowsWeightLevel2 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel2];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsWeightLevel2(newRows);
    calculateTotalsWeightLevel2(newRows);
  };

  const calculateTotalsWeightLevel2 = (rows2) => {
    const newTotals = rows2.reduce(
      (acc, row) => {
        acc.noOfPackageLoad += parseFloat(row.noOfPackageLoad) || 0;
        acc.portLoadingWeight1 += parseFloat(row.portLoadingWeight1) || 0;
        acc.portLoadingWeight2 += parseFloat(row.portLoadingWeight2) || 0;
        acc.averageWeight3 += parseFloat(row.averageWeight3) || 0;
        return acc;
      },
      {
        noOfPackageLoad: 0,
        portLoadingWeight1: 0,
        portLoadingWeight2: 0,
        averageWeight3: 0,
      }
    );
    setTotalsWeightLevel2(newTotals);
  };

  const addNewRowWeightLevel2 = () => {
    setRowsWeightLevel2([
      ...rowsWeightLevel2,
      {
        vehicleNumber: "",
        noOfPackageLoad: "",
        portLoadingWeight1: "",
        portLoadingWeight2: "",
        averageWeight3: "",
        dispatchDate: "",
        ewayBillNo: "",
      },
    ]);
  };

  const removeNewRowWeightLevel2 = () => {
    setRowsWeightLevel2(rowsWeightLevel2.slice(0, -1));
  };

  const [rowsWeightLevel3, setRowsWeightLevel3] = useState([
    {
      vehicleNumber: "",
      noOfPackageLoad: "",
      destinationWeight1: "",
      destinationWeight2: "",
      destinationWeight3: "",
      averageWeight4: "",
      materialReceivedDate: "",
    },
  ]);

  const [totalsWeightLevel3, setTotalsWeightLevel3] = useState({
    noOfPackageLoad: 0,
    destinationWeight1: 0,
    destinationWeight2: 0,
    destinationWeight3: 0,
    averageWeight4: 0,
  });

  const handleInputChangeRowsWeightLevel3 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel3];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsWeightLevel3(newRows);
    calculateTotalsWeightLevel3(newRows);
  };

  const calculateTotalsWeightLevel3 = (rows3) => {
    const newTotals = rows3.reduce(
      (acc, row) => {
        acc.noOfPackageLoad += parseFloat(row.noOfPackageLoad) || 0;
        acc.destinationWeight1 += parseFloat(row.destinationWeight1) || 0;
        acc.destinationWeight2 += parseFloat(row.destinationWeight2) || 0;
        acc.destinationWeight3 += parseFloat(row.destinationWeight3) || 0;
        acc.averageWeight4 += parseFloat(row.averageWeight4) || 0;
        return acc;
      },
      {
        noOfPackageLoad: 0,
        destinationWeight1: 0,
        destinationWeight2: 0,
        destinationWeight3: 0,
        averageWeight4: 0,
      }
    );
    setTotalsWeightLevel3(newTotals);
  };

  const addNewRowWeightLevel3 = () => {
    setRowsWeightLevel3([
      ...rowsWeightLevel3,
      {
        vehicleNumber: "",
        noOfPackageLoad: "",
        destinationWeight1: "",
        destinationWeight2: "",
        destinationWeight3: "",
        averageWeight4: "",
        materialReceivedDate: "",
      },
    ]);
  };

  const removeNewRowWeightLevel3 = () => {
    setRowsWeightLevel3(rowsWeightLevel3.slice(0, -1));
  };

  const renderWeightSummary = () => (
    <Box>
      <FormControl>
        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-I Weight Summary at CFS [Inside Port]
        </Heading>
        <Box>
          {rowsWeightLevel1.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Container Number</FormLabel>
                )}
                <Input
                  type="text"
                  name="containerNumber"
                  value={row.containerNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Number Of Package</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackage"
                  value={row.noOfPackage}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Invoice Net Weight</FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceNetWeight"
                  value={row.invoiceNetWeight}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Empty Container Weight
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="emptyContainerWeight"
                  value={row.emptyContainerWeight}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>CFS Net Weight-I</FormLabel>
                )}
                <Input
                  type="text"
                  name="cfsWeight1"
                  value={row.cfsWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>CFS Net Weight-II</FormLabel>
                )}
                <Input
                  type="text"
                  name="cfsWeight2"
                  value={row.cfsWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Average Weight-III</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight3"
                  value={row.averageWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="containerNumber"
              value="Total"
              isReadOnly
              focusBorderColor="teal.400"
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="noOfPackage"
              value={totalsWeightLevel1.noOfPackage}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="invoiceNetWeight"
              value={totalsWeightLevel1.invoiceNetWeight}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="emptyContainerWeight"
              value={totalsWeightLevel1.emptyContainerWeight}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="cfsWeight1"
              value={totalsWeightLevel1.cfsWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="cfsWeight2"
              value={totalsWeightLevel1.cfsWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight3"
              value={totalsWeightLevel1.averageWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
          </Flex>
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel1}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel1}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel1.length <= 1}
            />
          </Flex>
        </Box>

        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-II Weight Summary Of Loading at Destination CFS [Outside Port]
        </Heading>
        <Box>
          {rowsWeightLevel2.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Vehicle No</FormLabel>
                )}
                <Input
                  type="text"
                  name="vehicleNumber"
                  value={row.vehicleNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>No of Package Load</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackageLoad"
                  value={row.noOfPackageLoad}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Port Loading Weight-I
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="portLoadingWeight1"
                  value={row.portLoadingWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Port Loading Weight-II
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="portLoadingWeight2"
                  value={row.portLoadingWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Average Weight-III</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight3"
                  value={row.averageWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch Date</FormLabel>
                )}
                <Input
                  type="date"
                  name="dispatchDate"
                  value={row.dispatchDate}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Ewaybill No</FormLabel>
                )}
                <Input
                  type="text"
                  name="ewayBillNo"
                  value={row.ewayBillNo}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="vehicleNumber"
              value="Total"
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="noOfPackageLoad"
              value={totalsWeightLevel2.noOfPackageLoad}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="portLoadingWeight1"
              value={totalsWeightLevel2.portLoadingWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="portLoadingWeight2"
              value={totalsWeightLevel2.portLoadingWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight3"
              value={totalsWeightLevel2.averageWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="dispatchDate"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="ewayBillNo"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
          </Flex>

          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel2}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel2}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel2.length <= 1}
            />
          </Flex>
        </Box>

        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-III Weight Summary at Factory [Outside Port]
        </Heading>
        <Box>
          {rowsWeightLevel3.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Vehicle No</FormLabel>
                )}
                <Input
                  type="text"
                  name="vehicleNumber"
                  value={row.vehicleNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>No of Package Load</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackageLoad"
                  value={row.noOfPackageLoad}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-I
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight1"
                  value={row.destinationWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-II
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight2"
                  value={row.destinationWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-III
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight3"
                  value={row.destinationWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Average Weight-IV</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight4"
                  value={row.averageWeight4}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Material Received Date
                  </FormLabel>
                )}
                <Input
                  type="date"
                  name="materialReceivedDate"
                  value={row.materialReceivedDate}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="vehicleNumber"
              value="Total"
              focusBorderColor="teal.400"
              borderColor="gray.400"
              isReadOnly
            />
            <Input
              type="text"
              name="noOfPackageLoad"
              value={totalsWeightLevel3.noOfPackageLoad}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight1"
              value={totalsWeightLevel3.destinationWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight2"
              value={totalsWeightLevel3.destinationWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight3"
              value={totalsWeightLevel3.destinationWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight4"
              value={totalsWeightLevel3.averageWeight4}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="materialReceivedDate"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
          </Flex>
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel3}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel3}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel3.length <= 1}
            />
          </Flex>
        </Box>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Weight Summary
          </Heading>
        </Flex>
        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th textAlign="center" border={"1px solid gray"}></Th>
              <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
                Weight Details
              </Th>
              <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
                Shortage Details
              </Th>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Th textAlign="center" border={"1px solid gray"}>
                Particulars
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Weight
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Packages
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Weight
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Packages
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Invoice Weight & Package
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                CFS Weight Average
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Loading Weight Average
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Destination Weight Average
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
          </Tbody>
        </Table>
      </FormControl>
    </Box>
  );

  const renderCostingSummary = () => (
    <Box>
      <FormControl>
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  ></Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Basic
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Tax Amount
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Remarks
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Payment to Shipper-I [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    17850000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Payment to Shipper-II [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1500000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bank Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    4050.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bank Interest
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Clearing Agent Charges-I [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    8000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1800.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    CFS Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1400.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1200.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Shipping Charges-I
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    30450.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    2990.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Damage Charges-I
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Detention Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    17850000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Basic Custom Duty
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Social Welfare Surcharge
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    IGST Tax Amount
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1578500.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Additional Expenses
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Total Amount [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    19486950.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Item Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    17850000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Cost Per Kgs
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Total Invoice Quantity
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    105890.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    184.03
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Actual Average Qty Received
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    104970.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    185.64
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </FormControl>
    </Box>
  );

  const ThLabelStyle = {
    textAlign: "center",
    color: "teal",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "120px",
    fontSize: "10px",
  };

  const combineData = () => {
    return rowsWeightLevel1.map((row1, index) => {
      const row2 = rowsWeightLevel2[index] || {};
      const row3 = rowsWeightLevel3[index] || {};
      return {
        containerNumber: row1.containerNumber,
        containerWiseNetQty: row1.invoiceNetWeight,
        noOfBlockPerBundle: row1.noOfPackage,
        noOfPCs: row1.noOfPackage, // Adjust as per your data structure
        cfsNetWeight1: row1.cfsWeight1,
        cfsNetWeight2: row1.cfsWeight2,
        vehicleNo: row2.vehicleNumber,
        noOfBlockPerBundleLoad: row2.noOfPackageLoad,
        ewayBillQty: row2.portLoadingWeight1, // Adjust as per your data structure
        ewayBillNo: row2.ewayBillNo,
        ewayBillDate: row2.dispatchDate,
        portLoadingWeight1: row2.portLoadingWeight1,
        portLoadingWeight2: row2.portLoadingWeight2,
        deliveryLocationWeight1: row3.destinationWeight1,
        deliveryLocationWeight2: row3.destinationWeight2,
        deliveryLocationWeight3: row3.destinationWeight3,
        deliveryLocationAvgWeight2: row3.averageWeight4,
        materialReceivedDate: row3.materialReceivedDate,
      };
    });
  };

  const combinedData = combineData();

  // console.log("combinedData",combinedData)
  const renderShipmentSummary = () => (
    <Box>
      <FormControl>
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "start", color: "teal" }}
                  >
                    Shipment Details
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Important Names
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Important Dates
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Importer Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {formData.importingFirmName || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.exporterName || formData.exporterName}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Sales Contract
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {formData.salesContractNumber || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                {formData.typeOfImport === "High Sea Sale Import" && (
                  <>
                    <Tr boxShadow={"md"}>
                      <Td
                        style={{
                          textAlign: "start",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        HSS Seller Invoice No
                      </Td>
                      <Td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {shipmentWiseData.hssSellerInvoiceNo || ""}
                      </Td>
                      <Td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      ></Td>
                    </Tr>
                  </>
                )}

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Country Of Origin
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {value.label}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Type Of Duty
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.typeOfDuty || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Export Invoice Value (In USD)
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bill Of Lending No
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.lendingNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Shipping Line Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.shippingLine || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Clearing Agent Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.clearingHouseAgentName || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bill Of Entry
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {customerClearingStatusData.beNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {customerClearingStatusData.beDate || ""}
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Invoice No & Date
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.exporterInvoiceNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Invoice Value (In USD)
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>

          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Sl No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Container No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Container Wise Net Qty (in Kgs)
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Blocks / Bundle
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Pcs
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    CFS Net Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    CFS Net Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Vehicle No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Blocks / Bundle Load
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill Qty
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill Date
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Port Loading Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Port Loading Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-III
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Average Weight
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Material Received Date
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {combinedData.length > 0 &&
                  combinedData.map((el, index) => (
                    <Tr key={el.slNo} boxShadow={"md"}>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{index + 1}</Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.containerNumber}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.containerWiseNetQty}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.noOfBlockPerBundle}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.noOfPCs}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.cfsNetWeight1}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.cfsNetWeight2}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.vehicleNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.noOfBlockPerBundleLoad}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillQty}</Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillDate}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.portLoadingWeight1}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.portLoadingWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight1}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight3}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationAvgWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.materialReceivedDate}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Box>
              {combinedData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </FormControl>
    </Box>
  );

  // Rendering Each Tabs End Here //

  const renderShipmentDetailsForm = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} pl={5} pr={5} pt={1}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => setView("add")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Sales Contract Number :{" "}
            <span style={{ color: "black" }}>
              {formData.salesContractNumber}
            </span>
          </Heading>
        </Flex>

        <Flex justifyContent={"space-between"} gap={5}>
          <Button
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            onClick={handleSave}
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            Save Shipment
          </Button>
        </Flex>
      </Flex>
      <Tabs variant="soft-rounded" colorScheme="teal">
        <TabList>
          <Tab>Shipment Wise Details</Tab>
          <Tab>Documents Status</Tab>
          <Tab>Payment Status</Tab>
          <Tab>Customer Clearing Status</Tab>
          <Tab>Weight Summary Status</Tab>
          <Tab>Costing</Tab>
          <Tab>Shipment Summary</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{renderShipmentWise()}</TabPanel>
          <TabPanel>{renderDocumentStatus()}</TabPanel>
          <TabPanel>{renderPaymentStatus()}</TabPanel>
          <TabPanel>{renderCustomClearingStatus()}</TabPanel>
          <TabPanel>{renderWeightSummary()}</TabPanel>
          <TabPanel>{renderCostingSummary()}</TabPanel>
          <TabPanel>{renderShipmentSummary()}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        {view === "list" && renderList()}
        {view === "add" && renderAddForm()}
        {view === "edit" && renderEditForm()}
        {view === "info" && renderInfo()}
        {view === "shipmentdetailsform" && renderShipmentDetailsForm()}
        {view === "addedContracts" && renderAddedContracts()}
      </Flex>
    </Box>
  );
};

export default SalesContractDetails;
