import { Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import FinanceDashboard from './FinanceDashboard'

const FinanceDash = () => {
  return (
    <Flex>
      <Sidebar />
      <FinanceDashboard />
    </Flex>
  )
}

export default FinanceDash