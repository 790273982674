import { Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import Dashboard from '../Dashboard/Dashboard'

const Home = () => {
  return (
    <Flex>
        <Sidebar />
        <Dashboard />
    </Flex>
  )
}

export default Home