import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select as ChakraSelect,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { useLocation, useNavigate } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import Select from "react-select";
import countryList from "react-select-country-list";
import axios from "axios";
import { useAuth } from "../../../Context/AuthProvider";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { backendBaseURL } from "../../../AppConstants";
import { MdDeleteForever } from "react-icons/md";

const AddMShipDetails = () => {
  const location = useLocation();
  const contract = location.state?.contract;
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };
  const toast = useToast();
  const [view, setView] = useState("shipmentdetailsform");
  const navigation = useNavigate();

  const {
    itemNamesList,
    bankNamesList,
    clearingAgentsList,
    shippingLinesList,
    transporterNamesList,
    paginationData,
    shippingDetailsData,
  } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    salesContractNumber: "",
    salesContractDate: "",
    exporterName: "",
    salesContractContactPersonName: "",
    importingFirmName: "",
    typeOfDuty: "",
    typeOfImport: "",
    paymentMode: "",
    itemName: "",
    fixedQuantity: "",
    priceTerms: "",
    salesContractRemarks: "",
    floatingPriceRows: [],
    advancePaymentDetails: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      if (name === "paymentMode" && value === "Floating Price") {
        return {
          ...prevData,
          [name]: value,
          floatingPriceRows: [
            {
              date: "",
              quantity: "",
              lmePrice: "",
              rate: "",
              finalPrice: "",
            },
          ],
        };
      } else if (name === "paymentMode") {
        return {
          ...prevData,
          [name]: value,
          floatingPriceRows: [],
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const InputStyle = {
    width: { base: "100%", md: "70%", lg: "500px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  const SubInputStyle = {
    width: { base: "100%", md: "70%", lg: "240px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };
  const MultiInputStyle = {
    width: { base: "100%", md: "70%", lg: "260px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };
  const MiniMultiInputStyle = {
    width: { base: "100%", md: "70%", lg: "150px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  // Rendering Each Tabs Start Here //

  const [shipmentWiseData, setShipmentWiseData] = useState({});
  const [documentsStatusData, setDocumentsStatusData] = useState({});
  const [paymentStatusData, setPaymentStatusData] = useState({});
  const [customerClearingStatusData, setCustomerClearingStatusData] = useState(
    {}
  );
  const [shipmentSummaryData, setShipmentSummaryData] = useState({});
  const [costingData, setCostingData] = useState({});

  const [value, setValue] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (selectedOption) => {
    setValue(selectedOption);
    setShipmentWiseData((prevData) => ({
      ...prevData,
      shipmentcountry: selectedOption.label,
    }));
  };

  const handleInputChangeShipmentWise = (e) => {
    const { name, value } = e.target;
    setShipmentWiseData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChangePaymentStatus = (e) => {
    const { name, value } = e.target;
    setPaymentStatusData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChangeCustomClearingStatus = (e) => {
    const { name, value } = e.target;
    setCustomerClearingStatusData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [rows, setRows] = useState([
    {
      itemName: "",
      quantity: "",
      hsn: "",
      rate: "",
      currency: "",
      invoiceValue: "",
      difference: "",
    },
  ]);

  const handleInputChangeRows = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], [name]: value };
    setRows(newRows);
  };

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        itemName: "",
        quantity: "",
        hsn: "",
        rate: "",
        exchangeRate: "",
        hssValueUSD: "",
        hssValueINR: "",
        currency: "",
        invoiceValue: "",
        difference: "",
      },
    ]);
  };

  const removeNewRow = () => {
    setRows(rows.slice(0, -1));
  };

  const [paymentStatusRows, setPaymentStatusRows] = useState([
    {
      modeOfPayment: "",
      paymentDate: "",
      paymentBankName: "",
      transactionRefNo: "",
      paymentAmount: "",
      paymentCurrency: "",
      exchangeRate: "",
      amountInINR: "",
      bankCharges: "",
      creditPeriod: "",
      paymentDueDate: "",
    },
  ]);

  const handleInputChangePaymentStatusRows = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...paymentStatusRows];
    newRows[index] = { ...newRows[index], [name]: value };
    setPaymentStatusRows(newRows);
  };

  const addNewPaymentStatusRow = () => {
    setPaymentStatusRows([
      ...paymentStatusRows,
      {
        modeOfPayment: "",
        paymentDate: "",
        paymentBankName: "",
        transactionRefNo: "",
        paymentAmount: "",
        paymentCurrency: "",
        exchangeRate: "",
        amountInINR: "",
        bankCharges: "",
        creditPeriod: "",
        paymentDueDate: "",
      },
    ]);
  };

  const removeNewPaymentStatusRow = () => {
    setPaymentStatusRows(paymentStatusRows.slice(0, -1));
  };

  const handleSave = async () => {
    const allData = {
      salesContractNumber: contract[0].salesContractNumber,
      shipmentWiseData: {
        ...shipmentWiseData,
        exporterName: contract[0].exporterName,
        quantityInMTS: contract[0].fixedQuantity,
        typeOfDuty: contract[0].typeOfDuty,
        hssName: contract[0].exporterName,
      },
      rows,
      documentsStatusData: {
        draftStatusFromShipper,
        originalStatusFromShipper,
        draftStatusToClearingAgent,
        originalStatusToClearingAgent,
      },
      rowsShipper,
      rowsDeliver,
      paymentStatusRows,
      paymentStatusData,
      customerClearingStatusData,
      rowsWeightLevel1,
      rowsWeightLevel2,
      rowsWeightLevel3,
      totalsWeightLevel1,
      totalsWeightLevel2,
      totalsWeightLevel3,
      // shipmentSummaryData,
      // costingData,
    };

    try {
      await axios.post(`${backendBaseURL}/postShippingDetails`, allData);
      alert("Data saved successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data");
    }
  };

  const renderShipmentWise = () => (
    <Box>
      <FormControl>
        <Flex justifyContent={"space-around"} mt={5}>
          {contract[0].typeOfImport === "Direct Import" && (
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="exporterName"
                value={
                  shipmentWiseData.exporterName || contract[0].exporterName
                }
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
                isReadOnly
              />
            </Box>
          )}
          {contract[0].typeOfImport === "High Sea Sale Import" && (
            <Box>
              <FormLabel {...FormLabelStyle}>HSS Exporter Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="hssName"
                value={shipmentWiseData.hssName || contract[0].exporterName}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
          )}

          <Box {...InputStyle}>
            <FormLabel {...FormLabelStyle}>Country Of Origin</FormLabel>
            <Select options={options} value={value} onChange={changeHandler} />
          </Box>
        </Flex>

        {contract[0].typeOfImport === "High Sea Sale Import" && (
          <Flex justifyContent={"space-around"} mt={2}>
            <Box>
              <FormLabel {...FormLabelStyle}>Shipper Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="shipperName"
                value={shipmentWiseData.shipperName || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>HSS Seller Invoice No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="hssSellerInvoiceNo"
                value={shipmentWiseData.hssSellerInvoiceNo || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
          </Flex>
        )}

        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Type Of Duty</FormLabel>
            <ChakraSelect
              name="typeOfDuty"
              value={shipmentWiseData.typeOfDuty || contract[0].typeOfDuty}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Type Of Duty
              </option>
              <option value="Duty-Free">Duty-Free</option>
              <option value="With-Duty">With-Duty</option>
            </ChakraSelect>
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>Shipping Line Name</FormLabel>
            <ChakraSelect
              name="shippingLine"
              value={shipmentWiseData.shippingLine || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Shipping Line Name
              </option>
              {shippingLinesList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </ChakraSelect>
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Bill Of Lending Number</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="lendingNo"
              value={shipmentWiseData.lendingNo || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>
              Estimated Time of Arrival (ETA)
            </FormLabel>
            <Input
              type="date"
              placeholder=""
              name="eta"
              value={shipmentWiseData.eta || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Final Delivery Port</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="finalDeliveryPort"
              value={shipmentWiseData.finalDeliveryPort || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>Clearing House Agent Name</FormLabel>

            <ChakraSelect
              name="clearingHouseAgentName"
              value={shipmentWiseData.clearingHouseAgentName || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Clearing House Agent Name
              </option>
              {clearingAgentsList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </ChakraSelect>
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Quantity (In MTS)</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="quantityInMTS"
              value={
                shipmentWiseData.quantityInMTS || contract[0].fixedQuantity
              }
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
              isReadOnly
            />
          </Box>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Invoice No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="exporterInvoiceNo"
                value={shipmentWiseData.exporterInvoiceNo || ""}
                onChange={handleInputChangeShipmentWise}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Invoice Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="exporterInvoiceDate"
                value={shipmentWiseData.exporterInvoiceDate || ""}
                onChange={handleInputChangeShipmentWise}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Box>
          {rows.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                )}
                <ChakraSelect
                  name="itemName"
                  value={row.itemName || contract[0].itemName}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Item Name
                  </option>
                  {itemNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Quantity</FormLabel>
                )}
                <Input
                  type="text"
                  name="quantity"
                  value={row.quantity}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>HSN</FormLabel>}
                <Input
                  type="text"
                  name="hsn"
                  value={row.hsn}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>Rate</FormLabel>}
                <Input
                  type="text"
                  name="rate"
                  value={row.rate}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                )}
                <ChakraSelect
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Currency
                  </option>
                  <option value="USD">USD</option>
                  <option value="INR">INR</option>
                  <option value="EUR">Euro</option>
                  <option value="QAR">Qatar Riyal</option>
                  <option value="SAR">Saudi Riyal</option>
                </ChakraSelect>
              </Box>
              {contract[0].typeOfImport === "High Sea Sale Import" && (
                <>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    )}
                    <Input
                      type="text"
                      name="exchangeRate"
                      value={row.exchangeRate}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>HSS Value</FormLabel>
                    )}
                    <Input
                      type="text"
                      name="hssValueUSD"
                      value={row.hssValueUSD}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>
                        HSS Value In INR
                      </FormLabel>
                    )}
                    <Input
                      type="text"
                      name="hssValueINR"
                      value={row.hssValueINR}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                </>
              )}
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Exporter Invoice Value
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceValue"
                  value={row.invoiceValue}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Difference</FormLabel>
                )}
                <Input
                  type="text"
                  name="difference"
                  value={row.difference}
                  onChange={(e) => handleInputChange(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRow}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rows.length <= 1}
            />
          </Flex>
        </Box>
      </FormControl>
    </Box>
  );

  const initialDocuments = [
    "Loading Picture",
    "Bill of Lading",
    "Certificate of Origin",
    "Commercial Invoice",
    "Packing List",
    "Test Analysis Report",
    "Self-Declaration",
    "Form-I",
    "Flow Chart",
  ];

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleCheckboxChange = (doc) => {
    setSelectedDocuments((prev) =>
      prev.includes(doc) ? prev.filter((d) => d !== doc) : [...prev, doc]
    );
  };

  const [selectedClearingDocuments, setSelectedClearingDocuments] = useState(
    []
  );

  const handleCheckboxClearingChange = (doc) => {
    setSelectedClearingDocuments((prev) =>
      prev.includes(doc) ? prev.filter((d) => d !== doc) : [...prev, doc]
    );
  };

  const [draftStatusFromShipper, setDraftStatusFromShipper] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );
  const [originalStatusFromShipper, setOriginalStatusFromShipper] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: "" }), {})
  );
  const [
    originalStatusFromShipperChecked,
    setOriginalStatusFromShipperChecked,
  ] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );
  const [draftStatusToClearingAgent, setDraftStatusToClearingAgent] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );
  const [originalStatusToClearingAgent, setOriginalStatusToClearingAgent] =
    useState(
      initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: "" }), {})
    );
  const [
    originalStatusToClearingAgentChecked,
    setOriginalStatusToClearingAgentChecked,
  ] = useState(
    initialDocuments.reduce((acc, doc) => ({ ...acc, [doc]: false }), {})
  );

  const handleDraftChangeFromShipper = (doc) => {
    setDraftStatusFromShipper((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleDraftChangeToClearingAgent = (doc) => {
    setDraftStatusToClearingAgent((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleOriginalChange = (setOriginalStatus, doc, value) => {
    setOriginalStatus((prevState) => ({
      ...prevState,
      [doc]: value,
    }));
  };

  const handleOriginalCheckedChange = (setOriginalStatusChecked, doc) => {
    setOriginalStatusChecked((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleSelectAllDraft = (draftStatus, setDraftStatus) => {
    const allSelected = Object.values(draftStatus).every((status) => status);
    const newState = initialDocuments.reduce(
      (acc, doc) => ({ ...acc, [doc]: !allSelected }),
      {}
    );
    setDraftStatus(newState);
  };

  const [rowsShipper, setRowsShipper] = useState([
    {
      selectedDocuments: [],
      location: "",
      companyName: "",
      trackingDetails: "",
      receivingStatus: "",
      refNo: "",
    },
  ]);
  const handleCheckboxShipperChange = (index, doc) => {
    setRowsShipper((prev) => {
      const updatedRows = [...prev];
      const { selectedDocuments } = updatedRows[index];
      updatedRows[index].selectedDocuments = selectedDocuments.includes(doc)
        ? selectedDocuments.filter((d) => d !== doc)
        : [...selectedDocuments, doc];
      return updatedRows;
    });
  };

  const handleInputShipperChange = (index, field, value) => {
    setRowsShipper((prev) => {
      const updatedRows = [...prev];
      updatedRows[index][field] = value;
      return updatedRows;
    });
  };

  const addShipperRow = () => {
    setRowsShipper([
      ...rowsShipper,
      {
        selectedDocuments: [],
        location: "",
        companyName: "",
        trackingDetails: "",
        receivingStatus: "",
        refNo: "",
      },
    ]);
  };

  const removeShipperRow = () => {
    setRowsShipper(rowsShipper.slice(0, -1));
  };

  const [rowsDeliver, setRowsDeliver] = useState([
    {
      selectedDocuments: [],
      chaName: "",
      location: "",
      trackingDetails: "",
      deliveryStatus: "",
    },
  ]);
  const handleCheckboxDeliverChange = (index, doc) => {
    setRowsDeliver((prev) => {
      const updatedRows = [...prev];
      const { selectedDocuments } = updatedRows[index];
      updatedRows[index].selectedDocuments = selectedDocuments.includes(doc)
        ? selectedDocuments.filter((d) => d !== doc)
        : [...selectedDocuments, doc];
      return updatedRows;
    });
  };

  const handleInputDeliverChange = (index, field, value) => {
    setRowsDeliver((prev) => {
      const updatedRows = [...prev];
      updatedRows[index][field] = value;
      return updatedRows;
    });
  };

  const addDeliverRow = () => {
    setRowsDeliver([
      ...rowsDeliver,
      {
        selectedDocuments: [],
        chaName: "",
        location: "",
        trackingDetails: "",
        deliveryStatus: "",
      },
    ]);
  };

  const removeDeliverRow = () => {
    setRowsDeliver(rowsDeliver.slice(0, -1));
  };

  const renderDocumentStatus = () => (
    <Box p={4} overflowX="auto">
      <Flex justifyContent={"flex-start"}>
        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
          mb={5}
        >
          Document Status
        </Heading>
      </Flex>
      <Table variant="simple" size="sm" border={"1px solid gray"}>
        <Thead border={"1px solid gray"}>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}></Th>
            <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
              Documents Receiving Status from Shipper
            </Th>
            <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
              Documents Giving Status to Clearing Agent
            </Th>
          </Tr>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}>
              Documents Name
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Draft Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Original Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Draft Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Original Documents Status
            </Th>
          </Tr>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}></Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(draftStatusFromShipper).every(
                  (status) => status
                )}
                onChange={() =>
                  handleSelectAllDraft(
                    draftStatusFromShipper,
                    setDraftStatusFromShipper
                  )
                }
              />
              <span style={{ marginLeft: 10 }}>Select All </span>
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(
                  originalStatusFromShipperChecked
                ).every((status) => status)}
                onChange={() =>
                  handleSelectAllDraft(
                    originalStatusFromShipperChecked,
                    setOriginalStatusFromShipperChecked
                  )
                }
                // isChecked={false}
                // isReadOnly
              />
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(draftStatusToClearingAgent).every(
                  (status) => status
                )}
                onChange={() =>
                  handleSelectAllDraft(
                    draftStatusToClearingAgent,
                    setDraftStatusToClearingAgent
                  )
                }
              />
              <span style={{ marginLeft: 10 }}>Select All </span>
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(
                  originalStatusToClearingAgentChecked
                ).every((status) => status)}
                onChange={() =>
                  handleSelectAllDraft(
                    originalStatusToClearingAgentChecked,
                    setOriginalStatusToClearingAgentChecked
                  )
                }
                // isChecked={false}
                // isReadOnly
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {initialDocuments.map((doc) => (
            <Tr key={doc} border={"1px solid gray"}>
              <Td border={"1px solid gray"}>{doc}</Td>
              <Td border={"1px solid gray"}>
                <Checkbox
                  isChecked={draftStatusFromShipper[doc]}
                  onChange={() => handleDraftChangeFromShipper(doc)}
                />
              </Td>
              <Td border={"1px solid gray"}>
                <Flex justifyContent={"start"} gap={2}>
                  <Checkbox
                    isChecked={originalStatusFromShipperChecked[doc]}
                    onChange={() =>
                      handleOriginalCheckedChange(
                        setOriginalStatusFromShipperChecked,
                        doc
                      )
                    }
                    style={{ marginLeft: 10 }}
                  />
                  <Input
                    type="date"
                    value={originalStatusFromShipper[doc]}
                    onChange={(e) =>
                      handleOriginalChange(
                        setOriginalStatusFromShipper,
                        doc,
                        e.target.value
                      )
                    }
                  />
                </Flex>
              </Td>
              <Td border={"1px solid gray"}>
                <Checkbox
                  isChecked={draftStatusToClearingAgent[doc]}
                  onChange={() => handleDraftChangeToClearingAgent(doc)}
                />
              </Td>
              <Td border={"1px solid gray"}>
                <Flex justifyContent={"start"} gap={2}>
                  <Checkbox
                    isChecked={originalStatusToClearingAgentChecked[doc]}
                    onChange={() =>
                      handleOriginalCheckedChange(
                        setOriginalStatusToClearingAgentChecked,
                        doc
                      )
                    }
                    style={{ marginLeft: 10 }}
                  />
                  <Input
                    type="date"
                    value={originalStatusToClearingAgent[doc]}
                    onChange={(e) =>
                      handleOriginalChange(
                        setOriginalStatusToClearingAgent,
                        doc,
                        e.target.value
                      )
                    }
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Box mt={8}>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Original Documents Tracking from Shipper
          </Heading>
        </Flex>

        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th border={"1px solid gray"}>From Shipper</Th>
              <Th border={"1px solid gray"}>Courier Delivery Location</Th>
              <Th border={"1px solid gray"}>Courier Company Name</Th>
              <Th border={"1px solid gray"}>Tracking Details</Th>
              <Th border={"1px solid gray"}>Documents Receiving Status</Th>
              <Th border={"1px solid gray"}>
                Lodgement Status in Bank Ref No.
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowsShipper.map((row, index) => (
              <Tr key={index} border={"1px solid gray"}>
                <Td border={"1px solid gray"}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width={200}
                    >
                      <Box
                        as="span"
                        display="block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {row.selectedDocuments.length > 0
                          ? row.selectedDocuments.join(", ")
                          : "Complete Set of Documents"}
                      </Box>
                    </MenuButton>
                    <MenuList>
                      {initialDocuments.map((doc) => (
                        <MenuItem key={doc} closeOnSelect={false}>
                          <Checkbox
                            isChecked={row.selectedDocuments.includes(doc)}
                            onChange={() =>
                              handleCheckboxShipperChange(index, doc)
                            }
                            mr={2}
                          >
                            {doc}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Location"
                    value={row.location}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "location",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Company Name"
                    value={row.companyName}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "companyName",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Tracking Details"
                    value={row.trackingDetails}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "trackingDetails",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    type="date"
                    value={row.receivingStatus}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "receivingStatus",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Ref No."
                    value={row.refNo}
                    onChange={(e) =>
                      handleInputShipperChange(index, "refNo", e.target.value)
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt={4} justifyContent="flex-end" gap={2} wrap="wrap">
          <Button
            onClick={addShipperRow}
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Shipper
          </Button>
          <IconButton
            onClick={removeShipperRow}
            color="white"
            backgroundColor="red"
            borderRadius="15"
            _hover={{ backgroundColor: "red.400", color: "black" }}
            icon={<MdDeleteForever />}
            aria-label="Remove"
            isDisabled={rowsShipper.length <= 1}
          />
        </Flex>
      </Box>

      <Box mt={8}>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Original Documents Tracking to Clearing Agent House
          </Heading>
        </Flex>

        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th border={"1px solid gray"}>From Shipper</Th>
              <Th border={"1px solid gray"}>CHA Name</Th>
              <Th border={"1px solid gray"}>Location</Th>
              <Th border={"1px solid gray"}>Tracking Details</Th>
              <Th border={"1px solid gray"}>Documents Delivery Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowsDeliver.map((row, index) => (
              <Tr key={index} border={"1px solid gray"}>
                <Td border={"1px solid gray"}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width={200}
                    >
                      <Box
                        as="span"
                        display="block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {row.selectedDocuments.length > 0
                          ? row.selectedDocuments.join(", ")
                          : "Complete Set of Documents"}
                      </Box>
                    </MenuButton>
                    <MenuList>
                      {initialDocuments.map((doc) => (
                        <MenuItem key={doc} closeOnSelect={false}>
                          <Checkbox
                            isChecked={row.selectedDocuments.includes(doc)}
                            onChange={() =>
                              handleCheckboxDeliverChange(index, doc)
                            }
                            mr={2}
                          >
                            {doc}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="CHA Name"
                    value={row.chaName}
                    onChange={(e) =>
                      handleInputDeliverChange(index, "chaName", e.target.value)
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Location"
                    value={row.location}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "location",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Tracking Details"
                    value={row.trackingDetails}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "trackingDetails",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    type="date"
                    value={row.deliveryStatus}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "deliveryStatus",
                        e.target.value
                      )
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt={4} justifyContent="flex-end" gap={2} wrap="wrap">
          <Button
            onClick={addDeliverRow}
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Deliver
          </Button>

          <IconButton
            onClick={removeDeliverRow}
            color="white"
            backgroundColor="red"
            borderRadius="15"
            _hover={{ backgroundColor: "red.400", color: "black" }}
            icon={<MdDeleteForever />}
            aria-label="Remove"
            isDisabled={rowsDeliver.length <= 1}
          />
        </Flex>
      </Box>
    </Box>
  );

  const renderPaymentStatus = () => (
    <Box>
      <FormControl>
        <Box>
          {paymentStatusRows.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              flexDirection={"column"}
              key={index}
            >
              <Heading
                as="h6"
                size="xs"
                textAlign={"start"}
                textDecoration={"underline"}
              >
                Payment {index + 1}
              </Heading>
              <Flex justifyContent={"space-around"} mt={5}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Payment Term</FormLabel>
                    <ChakraSelect
                      name="modeOfPayment"
                      value={row.modeOfPayment || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    >
                      <option value="" disabled>
                        Payment Term
                      </option>
                      <option value="Cash">Cash</option>
                      <option value="TT">TT</option>
                      <option value="Buyer Credit">Buyer Credit</option>
                      <option value="LC">LC</option>
                      <option value="RTGS">RTGS</option>
                      <option value="Import Collection Bill">
                        Import Collection Bill
                      </option>
                    </ChakraSelect>
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Date</FormLabel>
                    <Input
                      type="date"
                      placeholder=""
                      name="paymentDate"
                      value={row.paymentDate || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                    <ChakraSelect
                      name="paymentBankName"
                      value={row.paymentBankName || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    >
                      <option value="" disabled>
                        Bank Name
                      </option>
                      {bankNamesList.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </ChakraSelect>
                  </Box>

                  <Box>
                    <FormLabel {...FormLabelStyle}>
                      Transaction Reference No
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="transactionRefNo"
                      value={row.transactionRefNo || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex justifyContent={"space-around"} mt={2}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Amount</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="paymentAmount"
                      value={row.paymentAmount || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                    <ChakraSelect
                      name="paymentCurrency"
                      value={row.paymentCurrency || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                      focusBorderColor="teal.400"
                    >
                      <option value="" disabled>
                        HSS Value
                      </option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="EUR">Euro</option>
                      <option value="QAR">Qatar Riyal</option>
                      <option value="SAR">Saudi Riyal</option>
                    </ChakraSelect>
                  </Box>
                </Flex>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="exchangeRate"
                      value={row.exchangeRate || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Amount In INR</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="amountInINR"
                      value={row.amountInINR || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex justifyContent={"space-around"} mt={2}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Bank Charges</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="bankCharges"
                      value={row.bankCharges || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Credit Period</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="creditPeriod"
                      value={row.creditPeriod || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
                <Box>
                  <FormLabel {...FormLabelStyle}>Due Date</FormLabel>
                  <Input
                    type="date"
                    placeholder=""
                    name="paymentDueDate"
                    value={row.paymentDueDate || ""}
                    onChange={(e) =>
                      handleInputChangePaymentStatusRows(e, index)
                    }
                    {...InputStyle}
                  />
                </Box>
              </Flex>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewPaymentStatusRow}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewPaymentStatusRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={paymentStatusRows.length <= 1}
            />
          </Flex>
        </Box>
        <TableContainer>
          <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
            <Thead>
              <Tr boxShadow={"sm"}>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "start", color: "teal" }}
                >
                  Particulars
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Currency
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Amount
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Remark
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  {contract[0].typeOfImport === "High Sea Sale Import"
                    ? "HSS Invoice Value"
                    : "Shipper Invoice Value"}
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceCurrency"
                    value={paymentStatusData.shipperInvoiceCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceAmount"
                    value={paymentStatusData.shipperInvoiceAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceRemarks"
                    value={paymentStatusData.shipperInvoiceRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Add: Other Amount
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountCurrency"
                    value={paymentStatusData.otherAmountCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountAmount"
                    value={paymentStatusData.otherAmountAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountRemarks"
                    value={paymentStatusData.otherAmountRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Amount Paid
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountCurrency"
                    value={paymentStatusData.lessAmountCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountAmount"
                    value={paymentStatusData.lessAmountAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountRemarks"
                    value={paymentStatusData.lessAmountRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Credit Note
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditCurrency"
                    value={paymentStatusData.lessCreditCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditAmount"
                    value={paymentStatusData.lessCreditAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditRemarks"
                    value={paymentStatusData.lessCreditRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Claims
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimCurrency"
                    value={paymentStatusData.lessClaimCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimAmount"
                    value={paymentStatusData.lessClaimAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimRemarks"
                    value={paymentStatusData.lessClaimRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Box>
            {paginationData.length === 0 && (
              <Alert
                status="info"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={4}
              >
                <AlertIcon boxSize={8} />
                No Record Found
              </Alert>
            )}
          </Box>
        </TableContainer>
      </FormControl>
    </Box>
  );

  const [rowsCCS, setRowsCCS] = useState([
    {
      dispatchFrom: "",
      dispatchTo: "",
      transporterName: "",
      transporterQty: "",
      transporterRate: "",
    },
  ]);

  const handleInputChangeRowsCCS = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsCCS];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsCCS(newRows);
  };

  const addNewRowCCS = () => {
    setRowsCCS([
      ...rowsCCS,
      {
        dispatchFrom: "",
        dispatchTo: "",
        transporterName: "",
        transporterQty: "",
        transporterRate: "",
      },
    ]);
  };

  const removeNewRowCCS = () => {
    setRowsCCS(rowsCCS.slice(0, -1));
  };

  const renderCustomClearingStatus = () => (
    <Box>
      <FormControl>
        <Flex justifyContent={"space-around"} mt={5}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Check List
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="checkListReceivedDate"
                value={customerClearingStatusData.checkListReceivedDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Approved</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="checkListApprovedDate"
                value={customerClearingStatusData.checkListApprovedDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Approved By</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="checkListApprovedBy"
                value={customerClearingStatusData.checkListApprovedBy || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Bill Of Entry
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>BE No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="beNo"
                value={customerClearingStatusData.beNo || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>BE Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="beDate"
                value={customerClearingStatusData.beDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>BE Payment</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="bePaymentDate"
                value={customerClearingStatusData.bePaymentDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Seal Photo
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="sealPhotoDate"
                value={customerClearingStatusData.sealPhotoDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Remark</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="sealPhotoRemarks"
                value={customerClearingStatusData.sealPhotoRemarks || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...InputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                CFS Weight
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="cfsWeightDate"
                value={customerClearingStatusData.cfsWeightDate || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Remark</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="cfsWeightRemarks"
                value={customerClearingStatusData.cfsWeightRemarks || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...InputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Box mt={25}>
          {rowsCCS.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch From</FormLabel>
                )}
                <Input
                  type="text"
                  name="dispatchFrom"
                  value={row.dispatchFrom}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch To</FormLabel>
                )}
                <Input
                  type="text"
                  name="dispatchTo"
                  value={row.dispatchTo}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transporter Name</FormLabel>
                )}
                <ChakraSelect
                  name="transporterName"
                  value={row.transporterName}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                  {...MiniMultiInputStyle}
                >
                  <option value="" disabled>
                    Transporter Name
                  </option>
                  {transporterNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>

              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Tranporter Quantity</FormLabel>
                )}
                <Input
                  type="text"
                  name="transporterQty"
                  value={row.transporterQty}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transporter Rate</FormLabel>
                )}
                <Input
                  type="text"
                  name="transporterRate"
                  value={row.transporterRate}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowCCS}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowCCS}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsCCS.length <= 1}
            />
          </Flex>
        </Box>
      </FormControl>
    </Box>
  );

  const [rowsWeightLevel1, setRowsWeightLevel1] = useState([
    {
      containerNumber: "",
      noOfPackage: "",
      invoiceNetWeight: "",
      emptyContainerWeight: "",
      cfsWeight1: "",
      cfsWeight2: "",
      averageWeight3: "",
    },
  ]);

  const [totalsWeightLevel1, setTotalsWeightLevel1] = useState({
    noOfPackage: 0,
    invoiceNetWeight: 0,
    emptyContainerWeight: 0,
    cfsWeight1: 0,
    cfsWeight2: 0,
    averageWeight3: 0,
  });

  const handleInputChangeRowsWeightLevel1 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel1];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsWeightLevel1(newRows);
    calculateTotals(newRows);
  };

  const calculateTotals = (rows) => {
    const newTotals = rows.reduce(
      (acc, row) => {
        acc.noOfPackage += parseFloat(row.noOfPackage) || 0;
        acc.invoiceNetWeight += parseFloat(row.invoiceNetWeight) || 0;
        acc.emptyContainerWeight += parseFloat(row.emptyContainerWeight) || 0;
        acc.cfsWeight1 += parseFloat(row.cfsWeight1) || 0;
        acc.cfsWeight2 += parseFloat(row.cfsWeight2) || 0;
        acc.averageWeight3 += parseFloat(row.averageWeight3) || 0;
        return acc;
      },
      {
        noOfPackage: 0,
        invoiceNetWeight: 0,
        emptyContainerWeight: 0,
        cfsWeight1: 0,
        cfsWeight2: 0,
        averageWeight3: 0,
      }
    );
    setTotalsWeightLevel1(newTotals);
  };

  const addNewRowWeightLevel1 = () => {
    setRowsWeightLevel1([
      ...rowsWeightLevel1,
      {
        containerNumber: "",
        noOfPackage: "",
        invoiceNetWeight: "",
        emptyContainerWeight: "",
        cfsWeight1: "",
        cfsWeight2: "",
        averageWeight3: "",
      },
    ]);
  };

  const removeNewRowWeightLevel1 = () => {
    setRowsWeightLevel1(rowsWeightLevel1.slice(0, -1));
  };

  const [rowsWeightLevel2, setRowsWeightLevel2] = useState([
    {
      vehicleNumber: "",
      noOfPackageLoad: "",
      portLoadingWeight1: "",
      portLoadingWeight2: "",
      averageWeight3: "",
      dispatchDate: "",
      ewayBillNo: "",
    },
  ]);

  const [totalsWeightLevel2, setTotalsWeightLevel2] = useState({
    noOfPackageLoad: 0,
    portLoadingWeight1: 0,
    portLoadingWeight2: 0,
    averageWeight3: 0,
  });

  const handleInputChangeRowsWeightLevel2 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel2];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsWeightLevel2(newRows);
    calculateTotalsWeightLevel2(newRows);
  };

  const calculateTotalsWeightLevel2 = (rows2) => {
    const newTotals = rows2.reduce(
      (acc, row) => {
        acc.noOfPackageLoad += parseFloat(row.noOfPackageLoad) || 0;
        acc.portLoadingWeight1 += parseFloat(row.portLoadingWeight1) || 0;
        acc.portLoadingWeight2 += parseFloat(row.portLoadingWeight2) || 0;
        acc.averageWeight3 += parseFloat(row.averageWeight3) || 0;
        return acc;
      },
      {
        noOfPackageLoad: 0,
        portLoadingWeight1: 0,
        portLoadingWeight2: 0,
        averageWeight3: 0,
      }
    );
    setTotalsWeightLevel2(newTotals);
  };

  const addNewRowWeightLevel2 = () => {
    setRowsWeightLevel2([
      ...rowsWeightLevel2,
      {
        vehicleNumber: "",
        noOfPackageLoad: "",
        portLoadingWeight1: "",
        portLoadingWeight2: "",
        averageWeight3: "",
        dispatchDate: "",
        ewayBillNo: "",
      },
    ]);
  };

  const removeNewRowWeightLevel2 = () => {
    setRowsWeightLevel2(rowsWeightLevel2.slice(0, -1));
  };

  const [rowsWeightLevel3, setRowsWeightLevel3] = useState([
    {
      vehicleNumber: "",
      noOfPackageLoad: "",
      destinationWeight1: "",
      destinationWeight2: "",
      destinationWeight3: "",
      averageWeight4: "",
      materialReceivedDate: "",
    },
  ]);

  const [totalsWeightLevel3, setTotalsWeightLevel3] = useState({
    noOfPackageLoad: 0,
    destinationWeight1: 0,
    destinationWeight2: 0,
    destinationWeight3: 0,
    averageWeight4: 0,
  });

  const handleInputChangeRowsWeightLevel3 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel3];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsWeightLevel3(newRows);
    calculateTotalsWeightLevel3(newRows);
  };

  const calculateTotalsWeightLevel3 = (rows3) => {
    const newTotals = rows3.reduce(
      (acc, row) => {
        acc.noOfPackageLoad += parseFloat(row.noOfPackageLoad) || 0;
        acc.destinationWeight1 += parseFloat(row.destinationWeight1) || 0;
        acc.destinationWeight2 += parseFloat(row.destinationWeight2) || 0;
        acc.destinationWeight3 += parseFloat(row.destinationWeight3) || 0;
        acc.averageWeight4 += parseFloat(row.averageWeight4) || 0;
        return acc;
      },
      {
        noOfPackageLoad: 0,
        destinationWeight1: 0,
        destinationWeight2: 0,
        destinationWeight3: 0,
        averageWeight4: 0,
      }
    );
    setTotalsWeightLevel3(newTotals);
  };

  const addNewRowWeightLevel3 = () => {
    setRowsWeightLevel3([
      ...rowsWeightLevel3,
      {
        vehicleNumber: "",
        noOfPackageLoad: "",
        destinationWeight1: "",
        destinationWeight2: "",
        destinationWeight3: "",
        averageWeight4: "",
        materialReceivedDate: "",
      },
    ]);
  };

  const removeNewRowWeightLevel3 = () => {
    setRowsWeightLevel3(rowsWeightLevel3.slice(0, -1));
  };

  const renderWeightSummary = () => (
    <Box>
      <FormControl>
        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-I Weight Summary at CFS [Inside Port]
        </Heading>
        <Box>
          {rowsWeightLevel1.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Container Number</FormLabel>
                )}
                <Input
                  type="text"
                  name="containerNumber"
                  value={row.containerNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Number Of Package</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackage"
                  value={row.noOfPackage}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Invoice Net Weight</FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceNetWeight"
                  value={row.invoiceNetWeight}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Empty Container Weight
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="emptyContainerWeight"
                  value={row.emptyContainerWeight}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>CFS Net Weight-I</FormLabel>
                )}
                <Input
                  type="text"
                  name="cfsWeight1"
                  value={row.cfsWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>CFS Net Weight-II</FormLabel>
                )}
                <Input
                  type="text"
                  name="cfsWeight2"
                  value={row.cfsWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Average Weight-III</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight3"
                  value={row.averageWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="containerNumber"
              value="Total"
              isReadOnly
              focusBorderColor="teal.400"
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="noOfPackage"
              value={totalsWeightLevel1.noOfPackage}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="invoiceNetWeight"
              value={totalsWeightLevel1.invoiceNetWeight}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="emptyContainerWeight"
              value={totalsWeightLevel1.emptyContainerWeight}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="cfsWeight1"
              value={totalsWeightLevel1.cfsWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="cfsWeight2"
              value={totalsWeightLevel1.cfsWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight3"
              value={totalsWeightLevel1.averageWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
          </Flex>
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel1}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel1}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel1.length <= 1}
            />
          </Flex>
        </Box>

        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-II Weight Summary Of Loading at Destination CFS [Outside Port]
        </Heading>
        <Box>
          {rowsWeightLevel2.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Vehicle No</FormLabel>
                )}
                <Input
                  type="text"
                  name="vehicleNumber"
                  value={row.vehicleNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>No of Package Load</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackageLoad"
                  value={row.noOfPackageLoad}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Port Loading Weight-I
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="portLoadingWeight1"
                  value={row.portLoadingWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Port Loading Weight-II
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="portLoadingWeight2"
                  value={row.portLoadingWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Average Weight-III</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight3"
                  value={row.averageWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch Date</FormLabel>
                )}
                <Input
                  type="date"
                  name="dispatchDate"
                  value={row.dispatchDate}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Ewaybill No</FormLabel>
                )}
                <Input
                  type="text"
                  name="ewayBillNo"
                  value={row.ewayBillNo}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="vehicleNumber"
              value="Total"
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="noOfPackageLoad"
              value={totalsWeightLevel2.noOfPackageLoad}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="portLoadingWeight1"
              value={totalsWeightLevel2.portLoadingWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="portLoadingWeight2"
              value={totalsWeightLevel2.portLoadingWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight3"
              value={totalsWeightLevel2.averageWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="dispatchDate"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="ewayBillNo"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
          </Flex>

          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel2}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel2}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel2.length <= 1}
            />
          </Flex>
        </Box>

        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-III Weight Summary at Factory [Outside Port]
        </Heading>
        <Box>
          {rowsWeightLevel3.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Vehicle No</FormLabel>
                )}
                <Input
                  type="text"
                  name="vehicleNumber"
                  value={row.vehicleNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>No of Package Load</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackageLoad"
                  value={row.noOfPackageLoad}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-I
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight1"
                  value={row.destinationWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-II
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight2"
                  value={row.destinationWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-III
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight3"
                  value={row.destinationWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Average Weight-IV</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight4"
                  value={row.averageWeight4}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Material Received Date
                  </FormLabel>
                )}
                <Input
                  type="date"
                  name="materialReceivedDate"
                  value={row.materialReceivedDate}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="vehicleNumber"
              value="Total"
              focusBorderColor="teal.400"
              borderColor="gray.400"
              isReadOnly
            />
            <Input
              type="text"
              name="noOfPackageLoad"
              value={totalsWeightLevel3.noOfPackageLoad}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight1"
              value={totalsWeightLevel3.destinationWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight2"
              value={totalsWeightLevel3.destinationWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight3"
              value={totalsWeightLevel3.destinationWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight4"
              value={totalsWeightLevel3.averageWeight4}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="materialReceivedDate"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
          </Flex>
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel3}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel3}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel3.length <= 1}
            />
          </Flex>
        </Box>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Weight Summary
          </Heading>
        </Flex>
        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th textAlign="center" border={"1px solid gray"}></Th>
              <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
                Weight Details
              </Th>
              <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
                Shortage Details
              </Th>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Th textAlign="center" border={"1px solid gray"}>
                Particulars
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Weight
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Packages
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Weight
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Packages
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Invoice Weight & Package
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                CFS Weight Average
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Loading Weight Average
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Destination Weight Average
              </Td>
              <Td border={"1px solid gray"}>2</Td>
              <Td border={"1px solid gray"}>3</Td>
              <Td border={"1px solid gray"}>4</Td>
              <Td border={"1px solid gray"}>5</Td>
            </Tr>
          </Tbody>
        </Table>
      </FormControl>
    </Box>
  );

  const renderCostingSummary = () => (
    <Box>
      <FormControl>
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  ></Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Basic
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Tax Amount
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Remarks
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Payment to Shipper-I [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    17850000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Payment to Shipper-II [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1500000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bank Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    4050.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bank Interest
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Clearing Agent Charges-I [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    8000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1800.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    CFS Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1400.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1200.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Shipping Charges-I
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    30450.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    2990.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Damage Charges-I
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Detention Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    17850000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Basic Custom Duty
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Social Welfare Surcharge
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    IGST Tax Amount
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    1578500.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Additional Expenses
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    0.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Total Amount [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    19486950.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Item Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    17850000.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Cost Per Kgs
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Total Invoice Quantity
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    105890.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    184.03
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Actual Average Qty Received
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    104970.00
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    185.64
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </FormControl>
    </Box>
  );

  const ThLabelStyle = {
    textAlign: "center",
    color: "teal",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "120px",
    fontSize: "10px",
  };

  const combineData = () => {
    return rowsWeightLevel1.map((row1, index) => {
      const row2 = rowsWeightLevel2[index] || {};
      const row3 = rowsWeightLevel3[index] || {};
      return {
        containerNumber: row1.containerNumber,
        containerWiseNetQty: row1.invoiceNetWeight,
        noOfBlockPerBundle: row1.noOfPackage,
        noOfPCs: row1.noOfPackage, // Adjust as per your data structure
        cfsNetWeight1: row1.cfsWeight1,
        cfsNetWeight2: row1.cfsWeight2,
        vehicleNo: row2.vehicleNumber,
        noOfBlockPerBundleLoad: row2.noOfPackageLoad,
        ewayBillQty: row2.portLoadingWeight1, // Adjust as per your data structure
        ewayBillNo: row2.ewayBillNo,
        ewayBillDate: row2.dispatchDate,
        portLoadingWeight1: row2.portLoadingWeight1,
        portLoadingWeight2: row2.portLoadingWeight2,
        deliveryLocationWeight1: row3.destinationWeight1,
        deliveryLocationWeight2: row3.destinationWeight2,
        deliveryLocationWeight3: row3.destinationWeight3,
        deliveryLocationAvgWeight2: row3.averageWeight4,
        materialReceivedDate: row3.materialReceivedDate,
      };
    });
  };

  const combinedData = combineData();

  // console.log("combinedData",combinedData)
  const renderShipmentSummary = () => (
    <Box>
      <FormControl>
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "start", color: "teal" }}
                  >
                    Shipment Details
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Important Names
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Important Dates
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Importer Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {contract[0].importingFirmName || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.exporterName || contract[0].exporterName}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Sales Contract
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {contract[0].salesContractNumber || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                {contract[0].typeOfImport === "High Sea Sale Import" && (
                  <>
                    <Tr boxShadow={"md"}>
                      <Td
                        style={{
                          textAlign: "start",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        HSS Seller Invoice No
                      </Td>
                      <Td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {shipmentWiseData.hssSellerInvoiceNo || ""}
                      </Td>
                      <Td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      ></Td>
                    </Tr>
                  </>
                )}

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Country Of Origin
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {value.label}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Type Of Duty
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.typeOfDuty || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Export Invoice Value (In USD)
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bill Of Lending No
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.lendingNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Shipping Line Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.shippingLine || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Clearing Agent Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.clearingHouseAgentName || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bill Of Entry
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {customerClearingStatusData.beNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {customerClearingStatusData.beDate || ""}
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Invoice No & Date
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.exporterInvoiceNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Invoice Value (In USD)
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>

          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Sl No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Container No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Container Wise Net Qty (in Kgs)
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Blocks / Bundle
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Pcs
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    CFS Net Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    CFS Net Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Vehicle No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Blocks / Bundle Load
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill Qty
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill Date
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Port Loading Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Port Loading Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-III
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Average Weight
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Material Received Date
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {combinedData.length > 0 &&
                  combinedData.map((el, index) => (
                    <Tr key={el.slNo} boxShadow={"md"}>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{index + 1}</Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.containerNumber}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.containerWiseNetQty}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.noOfBlockPerBundle}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.noOfPCs}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.cfsNetWeight1}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.cfsNetWeight2}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.vehicleNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.noOfBlockPerBundleLoad}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillQty}</Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillDate}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.portLoadingWeight1}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.portLoadingWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight1}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight3}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationAvgWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.materialReceivedDate}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Box>
              {combinedData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </FormControl>
    </Box>
  );

  // Rendering Each Tabs End Here //

  const renderShipmentDetailsForm = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} pl={5} pr={5} pt={1}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => navigation("/salescontract")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Sales Contract Number :{" "}
            <span style={{ color: "black" }}>
              {contract[0].salesContractNumber}
            </span>
          </Heading>
        </Flex>

        <Flex justifyContent={"space-between"} gap={5}>
          <Button
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            onClick={handleSave}
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            Save Shipment
          </Button>
        </Flex>
      </Flex>
      <Tabs variant="soft-rounded" colorScheme="teal">
        <TabList>
          <Tab>Shipment Wise Details</Tab>
          <Tab>Documents Status</Tab>
          <Tab>Payment Status</Tab>
          <Tab>Customer Clearing Status</Tab>
          <Tab>Weight Summary Status</Tab>
          <Tab>Costing</Tab>
          <Tab>Shipment Summary</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{renderShipmentWise()}</TabPanel>
          <TabPanel>{renderDocumentStatus()}</TabPanel>
          <TabPanel>{renderPaymentStatus()}</TabPanel>
          <TabPanel>{renderCustomClearingStatus()}</TabPanel>
          <TabPanel>{renderWeightSummary()}</TabPanel>
          <TabPanel>{renderCostingSummary()}</TabPanel>
          <TabPanel>{renderShipmentSummary()}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        {view === "shipmentdetailsform" && renderShipmentDetailsForm()}
      </Flex>
    </Box>
  );
};

export default AddMShipDetails;
